import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
import { UserprofileRoutingModule } from './userprofile-routing.module';
import { UserprofileComponent } from './userprofile.component';
import { ModalModule } from 'ngx-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    UserprofileRoutingModule,
    FormsModule,
    ModalModule
  ],
  declarations: [UserprofileComponent]
})
export class UserprofileModule { }
