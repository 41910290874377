
const BillNo = {
    type: "number",
    minimum: 1,
    maximum: 99999
}
const NoOfItems = {
    type: "number",
    minimum: 1,
    maximum: 999
}
const _Date = {
    format: "string",
}
const BillStatus = {
    type: 'string',
    minLength: 1,
    maxLength: 50
}
const PaymentMode = {
    type: 'string',
    minLength: 1,
    maxLength: 20
}
const CashCollected = {
    type: 'number',
    minimum: 0,
    maximum: 99999
}
const RefundAmt = {
    type: 'number',
    minimum: 0,
    maximum: 99999
}
const TotalCESS1 = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const TotalCESS2 = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const DiscVal = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const TotalAmt = {
    type: 'number',
    minimum: 0,
    maximum: 99999
}
const TotalGST = {
    type: 'number',
    minimum: 0,
    maximum: 100
}
const ItemServerDBID = {
    type: 'string',
    minLength: 24,
    maxLength: 24
}
const ICode = {
    type: "number",
    minimum: 1,
    maximum: 99999
}
const IName = {
    type: "string",
    minLength: 2,
    maxLength: 50
}
const HSN = {
    type: "string",
    minLength: 2,
    maxLength: 25
}
const Unit = {
    type: "string",
    minLength: 1,
    maxLength: 10
}
const PriceIndex = {
    type: 'number',
    minimum: 0,
    maximum: 100
}
const CP = {
    type: "number",
    minimum: 1,
    maximum: 9999999
}
const ItemStatus = {
    type: 'string',
    minLength: 1,
    maxLength: 20
}
const Qty = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const Price = {
    type: "number",
    minimum: 0,
    maximum: 9999999,
}
const TotalItemDisc = {
    type: 'number',
    minimum: 0,
    maximum: 99999
}
const GSTValue = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const Percent = {
    type: 'number',
    minimum: 0,
    maximum: 100
}
const CGSTPercent = {
    type: 'number',
    minimum: 0,
    maximum: 100
}
const CGSTValue = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const SGSTPercent = {
    type: 'number',
    minimum: 0,
    maximum: 100
}
const SGSTValue = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const IGSTPercet = {
    type: 'number',
    minimum: 0,
    maximum: 100
}
const IGSTValue = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const TotalGSTValue = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const CESS1Value = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const CESS2Value = {
    type: 'number',
    minimum: 0,
    maximum: 9999
}
const CESS1Percent = {
    type: 'number',
    minimum: 0,
    maximum: 100
}
const CESS2Percent = {
    type: 'number',
    minimum: 0,
    maximum: 100
}
const BasePrice = {
    type: 'number',
    minimum: 0,
    maximum: 99999
}
const CustomerNo = {
    type: 'number',
    minimum: 0,
    maximum: 250
}
const UserNo = {
    type: 'number',
    minimum: 0,
    maximum: 5
}
const ItemNetAmount = {
    type: 'number',
    minimum: 0,
    maximum: 99999
}
const Id = {
    type: "string",
    minLength: 24,
    maxLength: 24
}
const businessName = {
    type: "string",
    minLength: 2,
    maxLength: 30
}

const GSTN = {
    type: "string",
    minLength: 10,
    maxLength: 20
}
const telephone = {
    type: "string",
    minLength: 6,
    maxLength: 15
}
const fax = {
    type: "string",
    minLength: 5,
    maxLength: 20
}
const CIN = {
    type: "string",
    minLength: 5,
    maxLength: 50
}
const address = {
    type: "string",
    minLength: 2,
    maxLength: 150
}
const limit = {
    type: "string",
    minLength: 1,
    maxLength: 50
}
const page = {
    type: "string",
    minLength: 1,
    maxLength: 3
}
const city = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const pin = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const state = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const country = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const multiOutlet = {
    type: "boolean"
}
const productModelName = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const serialNo = {
    type: "string",
    minLength: 10,
    maxLength: 15
}
const key = {
    type: "string",
    minLength: 10,
    maxLength: 15
}
const previousOwner = {
    type: "string",
    minLength: 24,
    maxLength: 24
}
const currentOwner = {
    type: "string",
    minLength: 24,
    maxLength: 24
}
const activationStatus = {
    type: "string",
    minLength: 2,
    maxLength: 20
}
const soldDate = {
    type: "string",
    minLength: 2,
    maxLength: 20
}
const printerSize = {
    type: "string",
    minLength: 1,
    maxLength: 1
}
const factoryFirmwareVersion = {
    type: "string",
    minLength: 1,
    maxLength: 20
}
const currentFirmwareVersion = {
    type: "string",
    minLength: 1,
    maxLength: 20
}
const subscriptionExpiry = {
    type: "string",
    minLength: 1,
    maxLength: 20
}
const gracePeriod = {
    type: "string",
    minLength: 1,
    maxLength: 20
}
const remarks = {
    type: "string",
    minLength: 0,
    maxLength: 200
}
const deviceIdentifier = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const warrantyDuration = {
    type: 'number',
    minimum: 0,
    maximum: 60
}
const userDeviceName = {
    type:"string",
    minLength: 1,
    maxLength: 30
}
const boolean = {
    type: "boolean"
}

const CurrencyChange = {
    type: "boolean"
}


const ChickenToken = {
    type: "boolean"
}
const WeighingScaleNumber = {
    type: "number",
    minimum: 0,
    maximum: 10
}

const CustRecord = {
    type: "boolean"
}
const BillWithoutStock = {
    type: "boolean"
}

const ItemDiscountStatus = {
    type: "boolean"
}
const CalculatorMultiplier = {
    type: "boolean"
}
const OpenPriceStatus = {
    type: "boolean"
}
const SMSSettingstatus = {
    type: "boolean"
}
const RegistrationStatus = {
    type: "boolean"
}
const Timestamp = {
    type: "number",
    minimum: 1546300800000000, //Make sure that the timestamp is created after 1/1/2019 else consider it as invalid
    maximum: 9999999999999000
}
const getFileDetails = {
    type: "boolean"
}
const description = {
    type: "string",
    minLength: 2,
    maxLength: 50
}
const size = {
    type: "number",
    minimum: 0, 
    maximum: (4 * 1024)//as maximum filesize supported is 16MB
}
const offset = {
    type: "number",
    minimum: 0, 
    maximum: (4 * 1024)//as maximum filesize supported is 16MB
}
const fileName = {
    type: "string",
    minLength: 2,
    maxLength: 30 
}
const Text = {
    type: "string",
    minLength: 1,
    maxLength: 50
}
const Width = {
    type: "boolean"
}
const Height = {
    type: "boolean"
}
const Header = {
    type: "boolean"
}
const Footer = {
    type: "boolean"
}
const Alignment = {
    type: "string",
    minLength: 1,
    maxLength: 10
}
const LineNumber = {
    type: "number",
    minimum: 1,
    maximum: 20
}
const itemListName = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const DCode = {
    type: "number",
    minimum: 1,
    maximum: 999
}
const GCode = {
    type: "number",
    minimum: 1,
    maximum: 999
}
const SKU = {
    type: "string",
    minLength: 2,
    maxLength: 20
}
const DName = {
    type: "string",
    minLength: 2,
    maxLength: 50
}
const GName = {
    type: "string",
    minLength: 2,
    maxLength: 50
}
const IGST = {
    type: "number",
    minimum: 0,
    maximum: 100,
}
const SGST = {
    type: "number",
    minimum: 0,
    maximum: 100,

}
const CGST = {
    type: "number",
    minimum: 0,
    maximum: 100,

}
const CESS1 = {
    type: "number",
    minimum: 1,
    maximum: 100
}
const DiscPercent = {
    type: "number",
    minimum: 1,
    maximum: 100
}
const CESS2 = {
    type: "number",
    minimum: 1,
    maximum: 100
}
const OpenPriceFlag = {
    type: "boolean"
}
const DecimalDisableFlag = {
    type: "boolean"
}
const SortBy = {
    type: "string",
    minLength: 1,
    maxLength: 50
}
const Limit = {
    type: "string",
    minLength: 1,
    maxLength: 2
}
const Page = {
    type: "string",
    minLength: 1,
    maxLength: 3
}
const GetDeletedItem = {
    type: "boolean"
}
const outletName = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const modelNo = {
    type: "string",
    minLength: 0,
    maxLength: 30
}
const warranty = {
    type: "number",
    minLength: 0,
    maxLength: 60
}
const productname = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const Description = {
    type: "string",
    minLength: 5,
    maxLength: 50
}
const ActualPrice = {
    type: "number",
    minimum: 1,
    maximum: 99999
}
const DiscountedPrice = {
    type: "number",
    minimum: 1,
    maximum: 99999
}
const Currency = {
    type: "string",
    minLenght: 3,
    maxLength: 3
}
const Duration = {
    type: "number",
    minimum: 1,
    maximum: 24
}
const username = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const usertype = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const email = {
    format: "email",
    minLength: 5,
    maxLength: 60
}
const mobile = {
    type: "string",
    minLength: 10,
    maxLength: 15
}
const firstname = {
    type: "string",
    minLength: 2,
    maxLength: 30
}
const lastname = {
    type: "string",
    minLength: 1,
    maxLength: 30
}
const facebookID = {
    type: "string",
    minLength: 0,
    maxLength: 200
}
const imageUrl = {
    type: "string",
    minLength: 0,
    maxLength: 200
}
const alternatemobile = {
    type: "string",
    minLength: 10,
    maxLength: 15

}
const profilepic = {
    type: "string",
    minLength: 6,
    maxLength: 200
}
const accountstatus = {
    type: "string",
    minLength: 5,
    maxLength: 20
}
const user = {
    type : "string",
    minLength : 2,
    maxLength : 30
}
const password = {
    type : "string",
    minLength : 6,
    maxLength : 15
}
const otp = {
    type : "string",
    minLength : 4,
    maxLength : 6
}
const currentpassword = {
    type : "string",
    minLength : 6,
    maxLength : 20
}
const newpassword = {
    type : "string",
    minLength : 6,
    maxLength : 20
}
const token = {
    type : "string",
    minLength : 200,
    maxLength : 512
}
const duration={
    type:"number",
    minimum:0,
    maximum:9999
}

const paymentMode={
    type:"string",
    minLength:2,
    maxLength:100,
}

const paymentGatewayTransactionToken={
    type:"string",
    maxLength:2048,
}

const amount={
    type:"number",
    minimum:0,
    maximum:9999999
}
const date={
    type:"string",
    minLenght:2,
    maxLength:60,
}

const version = {
    type:"string",
    minLenght:1,
    maxLength:20,
}

const firmwareFlavour = {
    type:"string",
    minLenght:1,
    maxLength:100,
}

const status = {
    type:"string",
    minLenght:1,
    maxLength:50,
}

const Discount = {
    type: "number",
    minimum: 1,
    maximum: 99999
}

const OfferName = {
    type:"string",
    minLenght:1,
    maxLength:50,
}

const issueType = {
    type:"string",
    minLenght:1,
    maxLength:50,
}

const summary = {
    type:"string",
    minLenght:1,
    maxLength:50,
}

const Comment = {
    type:"string",
    minLenght:1,
    maxLength:200,
}

module.exports = {
    BillNo,
    NoOfItems,
    _Date,
    PaymentMode,
    CashCollected ,
    RefundAmt,
    TotalCESS1,
    TotalCESS2,
    DiscVal,
    TotalAmt,
    TotalGST,
    ItemServerDBID,
    ICode,
    IName,
    HSN,
    Unit,
    PriceIndex,
    CP,
    ItemStatus,
    Qty,
    Price,
    TotalItemDisc,
    GSTValue,
    Percent,
    CGSTPercent,
    CGSTValue,
    SGSTPercent,
    SGSTValue,
    IGSTPercet,
    IGSTValue,
    TotalGSTValue,
    CESS1Value,
    CESS2Value,
    CESS1Percent,
    CESS2Percent,
    BasePrice,
    BillStatus,
    ItemNetAmount,
    Id,
    businessName,
    GSTN,
    telephone,
    fax,
    CIN,
    address,
    limit,
    page,
    city,
    pin,
    state, 
    country,
    multiOutlet,
    productModelName,
    serialNo,
    key,
    previousOwner,
    currentOwner,
    activationStatus,
    soldDate,
    printerSize,
    factoryFirmwareVersion,
    currentFirmwareVersion,
    subscriptionExpiry,
    gracePeriod,
    remarks,
    deviceIdentifier,
    warrantyDuration,
    userDeviceName,
    HSN,
    boolean,
    CurrencyChange,
    ChickenToken,
    WeighingScaleNumber,
    CustRecord,
    BillWithoutStock,
    ItemDiscountStatus,
    CalculatorMultiplier,
    OpenPriceStatus,
    SMSSettingstatus,
    RegistrationStatus,
    Timestamp,
    getFileDetails,
    description,
    size,
    offset,
    fileName,
    Text,
    Width,
    Height,
    Header,
    Footer,
    Alignment,
    LineNumber,
    itemListName,
    ICode,
    DCode,
    GCode,
    SKU,
    HSN,
    DName,
    GName,
    IName,
    Unit,
    CP,
    Price,
    IGST,
    SGST,
    CGST,
    CESS1,
    DiscPercent,
    CESS2,
    OpenPriceFlag,
    DecimalDisableFlag,
    SortBy,
    Limit,
    Page,
    GetDeletedItem,
    outletName,
    GSTN,
    telephone,
    fax,
    address,
    city,
    pin,
    state,
    country,
    modelNo,
    productModelName,
    warranty,
    productname,
    Description,
    ActualPrice,
    DiscountedPrice,
    Currency,
    Duration,
    username,
    usertype,
    email,
    mobile,
    firstname,
    lastname,
    facebookID,
    imageUrl,
    telephone,
    CustomerNo,
    UserNo,
    alternatemobile,
    address,
    profilepic,
    accountstatus,
    user,
    password,
    otp,
    currentpassword,
    newpassword,
    token,
    paymentGatewayTransactionToken,
    duration,
    paymentMode,
    paymentGatewayTransactionToken,
    amount,
    date,
    version,
    firmwareFlavour,
    status,
    Discount,
    OfferName,
    issueType,
    summary,
    Comment
};




