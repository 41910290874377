import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { ToastrService} from 'ngx-toastr'
import { SettingsService } from 'app/settings/settings.service';
import { Router } from '@angular/router';
import { BusinessService } from 'app/services/business.service';
import { BsModalService, BsModalRef, ModalModule , ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss']
})
export class UserprofileComponent implements OnInit {
  @ViewChild('modalEditUser', {static: false}) public modalEditUser: ModalDirective;
  @ViewChild('modalEditBusiness', {static: false}) public modalEditBusiness: ModalDirective;


  public userId = localStorage.getItem('userid');
  public user: any = {};
  public tempuser: any = {};
  public business: any = {};
  public tempBusiness: any = {};
  public isUserEdit: Boolean = false;
  public isBusinessEdit : Boolean = false;

  constructor(
    private userService: UserService,
    private toasterService: ToastrService,
    private settingsService: SettingsService,
    private businessService: BusinessService,
    private router: Router
  ) {}
  ngOnInit() {
    this.userService.getUserDetails(this.userId)
      .subscribe(resp => {
        console.log('user profile ', resp);
        this.user = resp;
        console.log('user profile ', this.user);
        this.businessService.getBusinesses(1, 10)
          .subscribe(resp => {
            this.business = resp.docs[0];
            console.log('business ', this.business);
          })
      })
  }

  public onUserEditClick(){
    this.tempuser = JSON.parse(JSON.stringify(this.user));
    console.log('onEditClick()')
    this.modalEditUser.show()
    // if(this.isUserEdit){
    //   // save data 
    //   this.isUserEdit = false;
    // } else{
    //   this.isUserEdit = true;
    // }
  }
  public onBusinessEditClick(){
    this.tempBusiness = JSON.parse(JSON.stringify(this.business));
    console.log('onBusinessEditClick()')
    this.modalEditBusiness.show()
    // if(this.isBusinessEdit){
    //   // save data 
    // this.isBusinessEdit = false;
    // } else{
    //   this.isBusinessEdit = true;
    // }
  }


  editUserSubmit(user){
    delete user.mobile;
    this.userService.putUser(user)
    .subscribe( resp => {
      this.user = JSON.parse(JSON.stringify(this.tempuser));
      this.toasterService.success('Updated Successfully');
      this.modalEditUser.hide()
    }, error => {
      this.toasterService.error(error.error.message)
    })
  }

  editBusinessSubmit(business){
    business.businessId = this.business._id
    this.businessService.editBusiness(business)
    .subscribe( resp => {
      this.business = JSON.parse(JSON.stringify(this.tempBusiness));
      this.toasterService.success('Updated Successfully')
      this.modalEditBusiness.hide()
    }, error => {
      this.toasterService.error(error.error.message)
    })
  }

}
