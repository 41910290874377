import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceRoutingModule } from './device-routing.module';
import { DeviceComponent } from './device.component';
import { OutletService } from 'app/services/outlet.service';
import { ItemListService } from 'app/services/itemlist.service';
import { ProductService } from 'app/services/product.service';
import {DevicesService} from 'app/services/devices.service';
import { ItemsService } from 'app/services/items.service';
import {FormsModule} from '@angular/forms'
import { ModalModule } from 'ngx-bootstrap';
import { ToasterModule } from 'angular2-toaster';
import { CollapseModule, BsDropdownModule } from 'ngx-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { FileService } from 'app/services/file.service';

@NgModule({
  imports: [
    CommonModule,
    DeviceRoutingModule,
    FormsModule,
    ModalModule,
    ToasterModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot()

  ],
  declarations: [DeviceComponent],
  providers:[ItemListService,DevicesService,OutletService,ItemsService,ProductService,FileService],
})
export class DeviceModule { }
