import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable,throwError,of} from 'rxjs';
import {SettingsService} from '../settings/settings.service';
import {mergeMap,catchError} from 'rxjs/operators'

@Injectable()
export class DevicesService {

  constructor(private http: HttpClient, private setting: SettingsService) {
    console.log('device service created successfully')
   }

   public deviceReg(body): Observable<any> {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    console.log(body);
    if (token) {
    headers = headers.append('x-access-token', token)
    }
    const url = this.setting.getUrlDevice() + '/factoryregister';
    return this.http.post(url, body, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public generateSerialNo(body): Observable<any> {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    console.log(body);
    if (token) {
    headers = headers.append('x-access-token', token)
    }
    const url = this.setting.getUrlDevice() + '/generateSerialNo';
    return this.http.post(url, body, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public pilotDeviceReg(body): Observable<any> {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    console.log(body);
    if (token) {
    headers = headers.append('x-access-token', token)
    }
    const url = this.setting.getUrlDevice() + '/pilotfactoryregister';
    return this.http.post(url, body, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

   public create(body): Observable<any> {
    let headers = new HttpHeaders();
    const params = new HttpParams();
    const token = localStorage.getItem('token')
    if ( token ) {
    headers = headers.append('x-access-token', token)
    }
    const url = this.setting.getUrlDevice() + '/activation'
    return this.http.patch(url, body, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public getDeviceList(b_id,o_id): Observable<any> {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    const token = localStorage.getItem('token')
    if (token) {
    headers = headers.append('x-access-token', token)
    }
    const url = this.setting.getUrlDevice();
    // params = params.append('businessId', id)
    params = params.append('page', '1')
    params = params.append('limit', '10')
    if (b_id !== undefined){
    params = params.append('businessId', b_id)
    }
    if (o_id !== undefined){
    params = params.append('outletId',o_id);
    }
    return this.http.get(url, {headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public patchDevice(body): Observable<any> {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    if(token) {
      headers = headers.append('x-access-token', token);
    }
    const url = this.setting.getUrlDevice()+'/activation';
    console.log('patch device ', url)
    console.log('patch device ', token)
    return this.http.patch(url, body ,{headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public PatchM2IDSchema(body): Observable<any> {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    if(token) {
      headers = headers.append('x-access-token', token);
    }
    const url = this.setting.getUrlDevice()+'/maptoitemlist';
    console.log('patch PatchM2IDSchema ', url)
    console.log('patch PatchM2IDSchema ', token)
    return this.http.patch(url, body ,{headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public getDeviceSettings(d_id,terminaltoken): Observable<any> {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    const token = localStorage.getItem('token')
    if (token) {
    headers = headers.append('terminaltoken', terminaltoken)
    }
    const url = this.setting.getSettingsUrl();
    if (d_id !== undefined){
    params = params.append('deviceId', d_id)
    }
    return this.http.get(url, {headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public postDeviceSettings(body, token): Observable<any> {
    let headers = new HttpHeaders();
    const params = new HttpParams();
    // const token = localStorage.getItem('token')
    if ( token ) {
    headers = headers.append('terminaltoken', token)
    }
    const url = this.setting.getUrlDeviceSettings();

    return this.http.post(url, body, {headers: headers}).pipe(mergeMap(val=>{
      if(val['error'] !== undefined)
        return this.http.patch(url, body, {headers: headers}).pipe(catchError((err:Response) => {
          return  throwError(err)
        }))
      else
        return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public postDeviceHF(body, token): Observable<any> {
    let headers = new HttpHeaders();
    const params = new HttpParams();
    // const token = localStorage.getItem('token')
    if ( token ) {
    headers = headers.append('terminaltoken', token)
    }
    const url = this.setting.getUrlDeviceHF();

    console.log('HF post body ',token)
    return this.http.post(url, body, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  public getDeviceHF(d_id,token): Observable<any> {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    //const token = localStorage.getItem('token')
    if (token) {
    headers = headers.append('terminaltoken', token)
    }
    const url = this.setting.getUrlDeviceHF();
    if (d_id !== undefined){
    params = params.append('deviceId', d_id);
    // params = params.append('Header', 'true');
    // params = params.append('Footer', 'true');
    }
    return this.http.get(url, {headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  public patchDeviceHF(body, token): Observable<any> {
    let headers = new HttpHeaders();
    const params = new HttpParams();
    if ( token ) {
    headers = headers.append('terminaltoken', token)
    }
    const url = this.setting.getUrlDeviceHF();
    console.log('HF patch body ',body)
    return this.http.patch(url, body, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public putDeviceHF(body, token): Observable<any> {
    let headers = new HttpHeaders();
    const params = new HttpParams();
    if ( token ) {
    headers = headers.append('terminaltoken', token)
    }
    const url = this.setting.getUrlDeviceHF();
    console.log('HF patch body ',body)
    return this.http.put(url, body, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  public patchDeviceSettings(body, terminaltoken): Observable<any> {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    console.log('patchDeviceSettings ',terminaltoken)
    if ( token ) {
      headers = headers.append('terminaltoken', terminaltoken)
    }
    const url = this.setting.getUrlDeviceSettings();
    return this.http.patch(url, body, {headers: headers}).pipe(mergeMap(val=>{
      if(val['error'] !== undefined)
        return this.http.post(url, body, {headers: headers}).pipe(catchError((err:Response) => {
          return  throwError(err)
        }))
      else
        return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public getTerminalToken(body): Observable<any> {
    let headers = new HttpHeaders();
    const params = new HttpParams();
    const token = localStorage.getItem('token')
    const url = this.setting.getBackEndUrlUser() + '/login';
    console.log('getTerminalToken ', url)
    console.log('getTerminalToken ', body)
    return this.http.post(url, body, {headers: headers,}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
    
  }

  // public getTerminalToken(body): Observable<any> {
  //   let headers = new HttpHeaders();
  //   const params = new HttpParams();
  //   const token = localStorage.getItem('token')
  //   if ( token ) {
  //   headers = headers.append('x-access-token', token)
  //   }
  //   const url = this.setting.getBackEndUrlUser() + '/login';
  //   // const body = {
  //   //   deviceId : deviceId,
  //   //   token : token
  //   // }
  //   console.log('getTerminalToken ', url)
  //   return this.http.post(url, body, {headers: headers,})

  // }
}
