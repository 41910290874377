import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemsRoutingModule } from './items-routing.module';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
import { ToasterModule } from 'angular2-toaster';
import { CollapseModule, BsDropdownModule } from 'ngx-bootstrap';
import { ItemsComponent } from './items.component';
import { ItemsService } from 'app/services/items.service';
import { ItemListService } from 'app/services/itemlist.service';
import { DevicesService } from 'app/services/devices.service';
import { PaginationModule } from 'ngx-bootstrap/pagination';

@NgModule({
  imports: [
    CommonModule,
    ItemsRoutingModule,
    FormsModule,
    ModalModule,
    ToasterModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot()
  ],
  declarations: [ItemsComponent],
  providers: [ItemsService, ItemListService, DevicesService]
})
export class ItemsModule { }
