import { Observable, of as Observableof, throwError, of, pipe } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders,HttpErrorResponse,HttpHeaderResponse } from '@angular/common/http';
import { Router } from '@angular/router';
;
import { SettingsService } from '../settings/settings.service';
@Injectable()
export class AuthService {

  constructor(private http: HttpClient,private router:Router,public settings: SettingsService) {
  }
  splitHostname() {
    var full = window.location.host
    //window.location.host is subdomain.domain.com
    var parts = full.split('.')
    console.log(parts.length)
    var sub = parts[0]
    var domain = parts[1]
    var type = parts[2]
    console.log(sub)
    if(sub===null||sub==="")
      sub='ngxservice';
    sub = 'ngxservicetest'
    return sub
}

getOrgnToken() :Observable<boolean>
{
  var subdomain=this.splitHostname()
console.log("subdomain is %s",subdomain)
//subdomain='123459'
  let headers = new HttpHeaders();
 let url=this.settings.getBackEndUrlDBToken();
 console.log("orgntoken url %s",url+'/'+subdomain)
     return this.http.get(url+'/subdomain/'+subdomain, { headers:headers})
     .pipe(map((response)=>{
      console.log("subscribe orgn token error function" )
      console.log(response )
      if(response===null)
      {
      this.router.navigate(["/pages/404"])
      return (false)
      }
      console.log(response)
      localStorage.setItem('orgntoken', response['orgntoken']);

      return (true)
    }),catchError((err)=>{
      this.router.navigate(["/pages/404"]);
    //  return false;
      return Observableof(false);
    }))


}
isOrgnTokenValid(): Observable<boolean>
{

  return this.getOrgnToken();
}
  login(credentials) {
    //localStorage.setItem('currentUser', JSON.stringify({ 'x-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJOR1ggc2VydmljZSIsInN1YiI6IjVhM2E2M2YwNDg4ZDBlNTU1MDdmZmZhZiIsImlhdCI6MTUxNDg2ODYwNzc0OSwiZXhwIjoxNTIwMDUyNjA3NzQ5fQ.fGYY0r-1JhDb8XshNJhhPA3Yu5Rvg_XLhBl_l34XGYw", userid: "vinay" }));
    console.log( JSON.stringify({body:credentials}))
    let headers = new HttpHeaders();
    headers=headers.set('content-type','application/json')
    var orgntoken=localStorage.getItem('orgntoken')    
    if(orgntoken)   
      headers=headers.append('x-access-orgntoken',orgntoken)
     
    let url=this.settings.getBackEndUrlUser();  
    console.log(headers);
    console.log(credentials);
    console.log("url --- ",url);
   return this.http.post(url+'/login',
      JSON.stringify(credentials), { headers:headers})
      .pipe(mergeMap(val=>{
        return of(val)
      }), catchError((err:Response) => {
        return  throwError(err)
      }));
  }

  logout() {
  }

  isLoggedIn():Observable <boolean> {
    var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let headers = new HttpHeaders();
    console.log("inside isLoggedIn")
    var token=localStorage.getItem('token')
    console.log(token)
    if(token) {
     headers=headers.append('x-access-token',token);
    }
    else
    {
      this.router.navigate(["/pages/login"]);
     return Observableof(false);
    }
    let url=this.settings.getBackEndUrlUser();

    return this.http.get(url+'/authenticate', { headers:headers})
    .pipe(map((response:HttpHeaderResponse)=>{

      if(response.status===200)
      {
        return true;
      }
      else
      {
      return false;
      }

    }),catchError((err)=>{
      this.router.navigate(["/pages/login"]);
      //return false;
      return Observableof(false);
    }))

 //   return true;
  }

}
