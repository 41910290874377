import {
   Injectable 
} from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable,throwError,of} from 'rxjs';
import {SettingsService} from '../settings/settings.service';
import {mergeMap,catchError} from 'rxjs/operators'


@Injectable()
export class OutletService {

  constructor(private http: HttpClient, private setting: SettingsService) {
    console.log('outlet service created');
  }

  public getOutlet(businessId): Observable <any>{
    let headers = new HttpHeaders();
    let params = new HttpParams();
    const token = localStorage.getItem('token')
    if (token){
    headers = headers.append('x-access-token', token);
    }
    const url = this.setting.getOutletUrl()
   params = params.append('page', '1')
   params = params.append('limit', '50')
   params = params.append('businessId', businessId)
    return this.http.get(url, {headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public createOutlet(body):Observable<any>{
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    if (token) {
    headers = headers.append('x-access-token', token);
    }
    const url = this.setting.getOutletUrl()
    return this.http.post(url, body, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public updateOutlet(body): Observable<any>{
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    if (token) {
    headers = headers.append('x-access-token', token);
    }
    const url = this.setting.getOutletUrl()
    return this.http.patch(url, body, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

//this api is not implemented in serverside if it will implemented this will be tested
   public deleteOutlet(body): Observable<any> {
   let headers = new HttpHeaders();
  //  let params=new HttpParams();
   const token = localStorage.getItem('token')
   if (token) {
   headers = headers.append('x-access-token', token)
   }
    const url = this.setting.getOutletUrl()
   return this.http.delete(url, {headers: headers}).pipe(mergeMap(val=>{
    return of(val)
  }), catchError((err:Response) => {
    return  throwError(err)
  }));
}

}
