
import {  AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService:AuthService)  {
    console.log("In AuthGuard----constructor")
  }
  canActivate(): Observable<boolean>|Promise<boolean>|boolean
     {
      console.log("In AuthGuard----canActivate")
        return this.authService.isLoggedIn()
    }
}
