import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable,throwError,of} from 'rxjs';
import {SettingsService} from '../settings/settings.service';
import {mergeMap,catchError} from 'rxjs/operators'


@Injectable()
export class BusinessService {

  constructor(private http:HttpClient,private settings:SettingsService) {
    console.log('business service created')
  }
  public getBusinesses(page,limit): Observable < any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    const token = localStorage.getItem('token')
    if(token){
      headers = headers.append('x-access-token',token)
    }
    const url = this.settings.getUrlbusiness();
    params = params.append('page', page)
    params = params.append('limit', limit)
    return this.http.get(url,{ headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }


  public getBusiness(body): Observable < any > {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    let bodyKeys = [];
    const token = localStorage.getItem('token')
    if(token) {
      headers = headers.append('x-access-token',token)
    }
    bodyKeys = Object.keys(body);
    for (var i = 0; i < bodyKeys.length; i++) {
      params = params.append(bodyKeys[i], body[bodyKeys[i]]);
    }
    const url = this.settings.getUrlbusiness();
    return this.http.get(url, {headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public createBusiness(business): Observable < any >{
    let headers=new HttpHeaders();
     const token=localStorage.getItem('token')
     if(token)
     headers=headers.append('x-access-token',token)

     var url=this.settings.getUrlbusiness();

   const body = business
     return this.http.post(url,body,{headers:headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  public editBusiness(business): Observable < any >{
    let headers=new HttpHeaders();
     const token=localStorage.getItem('token')
     if(token)
     headers=headers.append('x-access-token',token)

     var url=this.settings.getUrlbusiness();

    //  const body = business
     return this.http.patch(url,business,{headers:headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

}
