import { Component, OnInit, ElementRef,ViewChild } from '@angular/core';

@Component({
  selector: 'app-logosetting',
  templateUrl: './logosetting.component.html',
  styleUrls: ['./logosetting.component.scss']
})
export class LogosettingComponent implements OnInit {
  public img;
  public logofile;
  public thresholdval=200;
  public scale=1;
  public filesize=0;
  public cx: CanvasRenderingContext2D;
  public cxOut: CanvasRenderingContext2D;
  public imagePath;
  @ViewChild("canvas",{static:true}) public canvas: ElementRef;
  @ViewChild("canvasOut",{static:true}) public canvasOut: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  onFileChanged(event) {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    const file = event.target.files[0];


    //reader.readAsDataURL(e.target.files[0]);
    //reader.readAsDataURL(event.target.files[0]);
    this.img = new Image();
    reader.onload = (event) => {
      this.logofile=reader.result;
      this.LoadFile()
    //   console.log(reader.result)
    //   var img = new Image();
    //   //this.img.src = reader.result;
    //   let ctx = this.cx;
    //   let ctxOut = this.cxOut;
    //   let gs= this.Grayscale;
    //   let canvas= this.canvas;
    //   this.imagePath=reader.result;
    //     img.src=reader.result as string;
    //  // ctx.drawImage(this.img, 0, 0);
    //    img.onload = function () {
    //     ctx.clearRect(0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
    //     ctx.drawImage(img, 0, 0);
    //     let imgData = ctx.getImageData(0, 0,canvas.nativeElement.width,canvas.nativeElement.height)
    //     let imgdsata1=gs(imgData,200)
    //     //debugger;
    //     ctxOut.clearRect(0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
    //     ctxOut.putImageData(imgdsata1, 0, 0);
    //    // ctxOut.drawImage(img, 0, 0);
    //    }

    }

  }

  LoadFile()
  {

    var file=this.logofile;
    var img = new Image();
    var thres=this.thresholdval;
    //this.img.src = reader.result;
    let ctx = this.cx;
    let ctxOut = this.cxOut;
    let gs= this.Grayscale;
    let canvas= this.canvas;
    let scale=this.scale;
    this.imagePath=file;
    //var scalefn=this.scaleImageData
      img.src=file as string;
   // ctx.drawImage(this.img, 0, 0);
     img.onload = function () {
      ctx.clearRect(0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
      ctx.save();
      ctx.scale(scale,scale);
      ctx.drawImage(img, 0, 0);
      ctx.restore();
      let imgData = ctx.getImageData(0, 0,canvas.nativeElement.width,canvas.nativeElement.height)
      let imgdsata1=gs(imgData,thres)
      //debugger;
      ctxOut.clearRect(0, 0, canvas.nativeElement.width, canvas.nativeElement.height);
      ctxOut.putImageData(imgdsata1, 0, 0);
     // ctxOut.drawImage(img, 0, 0);
     }

  }

 public Grayscale(pixels, threshold) {
     var d = pixels.data;
     for (var i=0; i<d.length; i+=4) {
      var r = d[i];
      var g = d[i+1];
      var b = d[i+2];
      var v = (0.2126*r + 0.7152*g + 0.0722*b >= threshold) ? 255 : 0;
      d[i] = d[i+1] = d[i+2] = v
     }
    return pixels;
  };

public calcFileSize(){
  return this.cxOut.getImageData(0, 0,this.canvas.nativeElement.width, this.canvas.nativeElement.height).data.length/(3*8*1024);
}

public textInConvas()
{
  const canvasElOut: HTMLCanvasElement = this.canvasOut.nativeElement;
  this.cxOut = canvasElOut.getContext('2d');
  let ctx = this.cx;
  ctx.fillText("ಕನ್ನಡದಲ್ಲಿ ಟೈಪ್ ಮಾಡಿ ", 10, 90);
}

  public ngAfterViewInit() {

    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;
     this.cx = canvasEl.getContext('2d');
    //  this.textInConvas();
    
  //  this.cxOut = canvasElOut.getContext('2d');
    //this.captureEvents(canvasEl);

    
    // ctx.font = "20px Georgia";
     //ctx.fillText("Hello World!", 10, 50);

     //ctx.font = "30px Verdana";
  //   //Create gradient
  //   var gradient = ctx.createLinearGradient(0, 0, this.canvas.nativeElement.width, 0);
  //   gradient.addColorStop(0, " magenta");
  //   gradient.addColorStop(0.5, "blue");
  //   gradient.addColorStop(1.0, "red");
  //   // Fill with gradient
  //   //ctx.fillStyle = gradient;
     //ctx.fillText("ಕನ್ನಡದಲ್ಲಿ ಟೈಪ್ ಮಾಡಿ ", 10, 90);

  //   console.log( canvasEl.toDataURL())
  //   this.imagePath=canvasEl.toDataURL();

  //   //this.cx1.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);


  //   //this.imgData = ctx.getImageData(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  //   //this.grayscale(this.imgData)
  //   let image = new Image();


  //   image.onload = ()=> {
  //     this.cx.drawImage(image, 0, 0);
  // }


  }



  public setThreshold(event)
  {
    console.log(event.target.value)
    this.thresholdval=event.target.value;
    this.LoadFile()
    this.filesize=this.calcFileSize()
  }
  public setScale(event)
  {
    console.log(event.target.value)
    this.scale=event.target.value;
    this.scale=this.scale/100;
    this.LoadFile()
    this.filesize=this.calcFileSize()*(this.scale*this.scale)
  }


}
