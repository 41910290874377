import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable,throwError,of} from 'rxjs';
import {SettingsService} from '../settings/settings.service';
import { FormGroup } from '@angular/forms';
import {mergeMap,catchError} from 'rxjs/operators'
@Injectable()
export class FileService {

  constructor(private http: HttpClient, private setting: SettingsService) {
    console.log('file service created');
  }

  public getFile(body, token): Observable <any>{
    let headers = new HttpHeaders();
    let params = new HttpParams();
    if (token) {
      headers = headers.append('terminaltoken', token);
    }
    const url = this.setting.getFileUrl()
    params = params.append('fileName', body.fileName);
    return this.http.get(url, {headers: headers, params: params, responseType: 'arraybuffer'}).pipe(catchError((err: Response) => {
      return  throwError(err)
    }))
  }

  public uploadFile(body, token):Observable <any> {
    let headers = new HttpHeaders();
    if (token) {
      headers = headers.append('terminaltoken', token);
    }
    const url = this.setting.getFileUrl()
    return this.http.post(url, body, {headers: headers}).pipe(mergeMap(val=>{
      if(val['error'] !== undefined)
        return this.http.put(url, body, {headers: headers}).pipe(catchError((err:Response) => {
          return  throwError(err)
        }))
      else
        return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  public updateFile(body): Observable <any> {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    if (token) {
      headers = headers.append('x-access-token', token);
    }
    const url = this.setting.getFileUrl()
    return this.http.patch(url, body, {headers: headers})
  }

  public deleteFile(body): Observable <any> {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    if (token) {
      headers = headers.append('x-access-token', token)
    }
    const url = this.setting.getFileUrl()
    return this.http.delete(url, {headers: headers})
  }
}
