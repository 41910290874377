import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef, ModalModule , ModalDirective } from 'ngx-bootstrap/modal';
import { ToastContainerDirective, ToastrService} from 'ngx-toastr'
import { SettingsService } from 'app/settings/settings.service';
import { Router } from '@angular/router';
import { ItemsService } from 'app/services/items.service';
import { ItemListService } from 'app/services/itemlist.service';
import { DevicesService } from 'app/services/devices.service';
import { from } from 'rxjs';
import  * as Ajv from 'ajv'
import {VBItem,ItemDSchema} from '../../models/validate/items'
import { throttleTime, timeout } from 'rxjs/operators';
import { forkJoin } from 'rxjs/';
import { Utils, idLocale } from 'ngx-bootstrap';
import { logging } from 'selenium-webdriver';
import { saveAs, Save } from 'file-saver';
import { error } from '@angular/compiler/src/util';
import * as XLSX from 'xlsx';
interface IPrice{
  Price:number;
  IGST:number;
  CGST:number;
  SGST:number;
}
interface IItem {
  IName:string,
  HSN:string,
  ICode:number,
  Unit:string,
  Price:IPrice[],
  DCode:number,
  DName:string,
  GName:string,
  GCode:number,
  CP:number,
  CESS1:number,
  CESS2:number,
  DiscPercent:number,
  DecimalDisableFlag:Boolean,
  OpenPriceFlag:Boolean,
}
@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent implements OnInit {
  @ViewChild('modalCreateItem', {static: false}) public modalCreateItem: ModalDirective;
  @ViewChild('modalCreateItemFocusInput', {static: false}) modalCreateItemFocusInput: ElementRef;
  @ViewChild('modalCreateItemList', {static: false}) public modalCreateItemList: ModalDirective;
  @ViewChild('modalItemList',{static: false}) public modalItemList: ModalDirective;
  @ViewChild('modalItemListFocusInput', {static: false}) modalItemListFocusInput: ElementRef;
  @ViewChild('modalDeleteItem',{static: false}) public modalDeleteItem: ModalDirective;
  @ViewChild('modalDeleteItemFocusInput', {static: false}) modalDeleteItemFocusInput: ElementRef;
  @ViewChild('modalDeleteAllItems',{static: false}) public modalDeleteAllItems: ModalDirective;
  @ViewChild('modalDeleteAllItemsFocusInput', {static: false}) modalDeleteAllItemsFocusInput: ElementRef;
  @ViewChild('modalDeleteItemList',{static: false}) public modalDeleteItemList: ModalDirective;
  @ViewChild('modalWaitForDelete',{static: false}) public modalWaitForDelete: ModalDirective;
  @ViewChild('modalWaitForUpload',{static: false}) public modalWaitForUpload: ModalDirective;

  @ViewChild('fileInput', {static: false})
  
  public fileInputVariable: ElementRef;

  samplefileformat=[{'HSN':"abcdefgh",'ICode':1,'IName':"TestItemName",'Unit':"Pcs",'IPrice1':250,'CGST1':5,'SGST1':5,'IGST1':8,'IPrice2':200,'CGST2':4,'SGST2':4,'IGST2':4,'IPrice3':150,'CGST3':3,'SGST3':3,'IGST3':7,'CP':1,'GCODE':1,'GNAME':"Itemtest",'DCODE':1,'DNAME':"Itemtest",'OPF':1,'DecimalStatus':1,'DIS':1,'CESS1':0,'CESS2':0}];
  terminalToken: string = '';//'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1Y2M5OTI2MzE3Y2JmOTY0YTM0MGFjMzUiLCJ1c2VydHlwZSI6ImRlZmF1bHQiLCJpYXQiOjE1NjA4NTc0MjA3NjUsImV4cCI6MTU2NjA0MTQyMDc2NSwiZGV2aWNlSWQiOiI1Y2RkMzQ1MjI0ODMyYmZmNmQzN2Q1NjQiLCJidXNpbmVzc0lkIjoiNWNmYTU2ZWQyZjI5ZjcwMjA4MzQwZDI4Iiwib3V0bGV0SWQiOiI1Y2NkOGI2NjkxYzM1MDY5N2I1OWQ3YzEifQ.hMkgg6f-88vpikKd_aaalWtO0D_nPVHDmTy0BOhVaXo';

  public itemListObj: any;
  public ItemObj: any = {
    Price: [{}, {}, {}]
  };
  ItemOBJ:IItem;

  private schema = {

    type: 'object',
    properties: {
       hello: { type: 'number',
               minimum:5,
               maximum:25 } },

    additionalProperties: false,
    required: ['hello']

  };

  //importeditem={} as IItem;
  public page = 1;
  public page_il = 1;
  public limit = 15;
  public limit_il = 100;
  public Items: any;
  public businessId: any;
  public SelectedItemList: number;
  public SelectedILName: any ='';
  public isPost: Boolean = true;
  public itemsList: any;
  public isTable: Boolean = false;
  public isCreate: Boolean = false;
  public showItemList: Boolean = false;
  public itemToDelete: any ={};
  public isItemListEdit: Boolean = false;
  public itemList: any = '';
  public itemListName: any = '';
  public selectedRow:any= '';
  public IName: any = '';
  public itemListSelected = false;
  public queryResponseArrived = true;
  csvContent: string;
  importedList = [] as IItem[];
  resp: any;
  object: any;
  object1: any;
  index;
  SelectedItemList1: number;
  tempObject;
  deletItemListEdit: boolean;
  modalDeletItemList: any;
  public isItemListEdit1: Boolean = false;
 
  constructor(
    private itemsService: ItemsService,
    private itemListService: ItemListService,
    private toasterService: ToastrService,
    private settingService: SettingsService,
    private devicesService: DevicesService,
  ) {}

  ngOnInit() {
    this.businessId = this.settingService.getBusinessId();
    this.itemListSelected = false;
    this.queryResponseArrived = false;
    // this.terminalToken = localStorage.getItem('terminaltoken');
    this.getTerminalToken();
    console.log('TT ',this.terminalToken)
     const ajv = new Ajv({ allErrors: true });
     const test = ajv.compile(ItemDSchema);
     const obj = { hello1: 3 }
     const isValid = test(obj);
     console.log(isValid ? obj : { obj, error: test.errors })
  }
  getTerminalToken(){
    const body = {
      token : localStorage.getItem('token'),
      businessId : localStorage.getItem('businessId')
    }
    this.devicesService.getTerminalToken(body)
    .subscribe( resp => {
      this.terminalToken = resp.token
      console.log('terminal token ', resp )
      this.getItemList();
    })
  }
  public onkeydown = function(e) {
    if(!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58) 
      || e.keyCode == 8)) {
        if ((e.keyCode != 190) && (e.keyCode != 110)) {
          if (e.keyCode === 9) {
            e.stopPropagation();
            return true;
          } else {
            return false;
          }
        }
    }
  }
  public onSearchClick(){
    console.log(this.IName)
    this.getItems(this.SelectedItemList)
  }
  public getItemList() {
    this.itemListService.get(this.businessId,this.page_il,this.limit_il)
      .subscribe(resp => {
          this.itemListObj = resp.docs
          if (this.itemListObj.length > 0) {
          this.itemListSelected = true;
          this.SelectedItemList = this.itemListObj[0]._id;
          this.SelectedILName = this.itemListObj[0].itemListName
          this.getItems(this.SelectedItemList);       
      }        
      }, error => {
        console.log('Not created Successfully')
      })
  }
  public createItemList(itemList) {
    itemList.businessId = this.settingService.getBusinessId();
    if(this.isItemListEdit){
      itemList.itemListId = this.SelectedItemList;
      itemList.itemListName = this.itemListName; 
      this.itemListService.patch(itemList)
      .subscribe(resp => {
        this.modalCreateItemList.hide();
        this.toasterService.success('ItemList Modified successfully');
        this.getItemList();
      }, error => {     
        console.log(error);      
      })
    } else {
      itemList.itemListName = this.itemListName;
      this.itemListService.create(itemList)
      .subscribe(resp => {
        this.modalCreateItemList.hide();
        this.toasterService.success('ItemList Created successfully'); 
        this.SelectedItemList = itemList._id;
        this.getItemList();
      }, error => {
        console.log(error)
        if (error.error.message != undefined) {
          if(error.error.message.includes('Post data input error')=== true){
            this.toasterService.error("Enter ItemList Name");
          }
          else if(error.error.message != undefined){
          if(error.error.message.includes('Duplicate Item List')=== true){
            this.toasterService.error("ItemList already Exists");
          }
        }    

      }
      })
    }
  }
  public createItemListModel() {
    this.itemListName = ""
    this.isItemListEdit1= true
    this.deletItemListEdit = false
    this.isItemListEdit = false
    this.modalCreateItemList.show();
    setTimeout(() => {
      this.modalItemListFocusInput.nativeElement.focus();
    }, 500);
  }

  public deleteItem(){
    this.itemsService.delete(this.itemToDelete, this.terminalToken)
    .subscribe(resp => {
      this.toasterService.success('Item Deleted');
      this.modalDeleteItem.hide()
      this.getItems(this.SelectedItemList);
    }, error => {
      this.toasterService.error(error.error.message);
    })
  }

  public deleteAllItems(){
    if (this.Items){
    this.modalDeleteAllItems.hide();
    this.modalWaitForDelete.show();
    this.itemsService.deleteAllItems(this.SelectedItemList, this.terminalToken)
    .subscribe(resp => {
      console.log(resp)
      if(this.SelectedItemList == 0 ){
        this.toasterService.success('This ItemList Name Deleted Successfully');
      }else {
        this.toasterService.success('All Items Deleted Successfully');
        this.page = 1;
        this.getItems(this.SelectedItemList);
        this.modalWaitForDelete.hide();
        // this.modalDeleteAllItems.hide();
      }
    }, error => {
      console.log(error)
      this.modalWaitForDelete.hide();
      if ((error.error != undefined) && (error.error != null)) {
        if ((error.error.message != undefined) && (error.error.message != null)) {
          this.toasterService.error(error.error.message);
        }
      }
    })
  } else { this.toasterService.error("No items found");
    this.modalDeleteAllItems.hide();
    }
  }

  public allItemListClick(){
    this.showItemList = true;
  }

  public createItem(ItemObj) {
    let obj = JSON.parse(JSON.stringify(ItemObj));
    obj.Price = obj.Price.filter(value => Object.keys(value).length !== 0);
    if (this.isPost) {
      this.itemsService.post(obj, this.SelectedItemList, this.terminalToken)
      .subscribe(resp => {
          this.modalCreateItem.hide()
          this.toasterService.success('Create Item successful');
          if (this.SelectedItemList != null) {
            this.getItems(this.SelectedItemList);
          }
        },error =>{
          if(error.error.error[0].message.includes("should have required property 'IName'") === true ){
            this.toasterService.error("Item Name is Required");
          }
        })
    } else {
      let items: any = [{}];
      delete obj._id;
      delete obj.deleteStatus;
      delete obj.ItemListId;
      delete obj.Timestamp;
      for (let i = 0; i < obj.Price.length; i++) {
        delete obj.Price[i]._id;
      }
      items[0] = obj;
      let isEnforce = false;
      this.itemsService.put(items, this.SelectedItemList, this.terminalToken,isEnforce)
        .subscribe(resp => {
          this.modalCreateItem.hide()
          this.toasterService.success('Updated Item details successful');
          if (this.SelectedItemList != null) {
            this.getItems(this.SelectedItemList);
          }
        })
    }
  }
  public createItemModel(ItemList) {
    if(ItemList != null)
    {
      this.SelectedItemList = ItemList._id;
      this.SelectedILName = ItemList.itemListName
    }
    this.ItemObj = {
      Price: [{}, {}, {}],
      ICode: 0
    };
    this.isCreate = true;
    this.modalCreateItem.show();
    setTimeout(() => {
      this.modalCreateItemFocusInput.nativeElement.focus();
    }, 500);
    this.isPost = true;
    this.itemsService.getItemCodeForList(this.SelectedItemList, this.terminalToken)
    .subscribe(resp => {
      for (var i = 0; i < resp.length; i++) {
        if ((i + 1) != resp[i].ICode) {
          break;
        }
      }
      this.ItemObj.ICode = (i + 1);
    }, err => {
      console.log(err);
      if (err.error.message.includes('Items not found') === true) {
        this.ItemObj.ICode = 1;
      }
    })
  }

  public getItems(id) {
    console.log(id, this.IName, this.page, this.limit);
    this.itemsService.getItems(id, this.IName, this.terminalToken, this.page, this.limit)
    .subscribe(resp => {  
      this.Items = resp.docs;
      this.itemsList = resp
      if (this.Items !== undefined) {
        for (let j = 0; j < this.Items.length; j++) {
          for (let i = 0; i < 3; i++) {
            if (this.Items[j].Price[i] === undefined) {
              this.Items[j].Price[i] = {};
            }
          }
        }
        this.queryResponseArrived = true;
      }
    },err=>{ 
      console.log(err);
      this.queryResponseArrived = true;
    })   
  } 
  public onItemListChange() {
    console.log('onItemListChange')
    this.page = 1; this.limit = 15;
    this.getItems(this.SelectedItemList);
  }
  public onItemListClick(ItemList,i){
    this.page = 1;
    this.IName = "";
    this.SelectedItemList = ItemList._id;
    this.SelectedILName = ItemList.itemListName
    this.getItems(ItemList._id);
    this.selectedRow=i;
    console.log("selected=",i)
    this.index=this.selectedRow;  
  } 
  public deleteItemList(itemListName) {
    this.itemListService.patch(this.itemList)
    .subscribe(resp => {
      this.modalDeleteItemList.hide();
      this.toasterService.success('ItemList deleted successfully');
      this.getItemList();
    }, error => {
      console.log('error======================>', error);
    })
  }
  public onItemEditClick(Item) {
    this.isCreate = false;
    this.modalCreateItem.show();
    setTimeout(() => {
      this.modalCreateItemFocusInput.nativeElement.focus();
    }, 500);
    this.ItemObj = JSON.parse(JSON.stringify(Item));
    this.isPost = false;
  }
  public onItemDeleteClick(item) {
    console.log('delete item ',item)
    this.itemToDelete = item;
    this.modalDeleteItem.show();
    setTimeout(() => {
      this.modalDeleteItemFocusInput.nativeElement.focus();
    }, 500);
  }
  
  public onDeleteAllItemsClick() {
    this.modalDeleteAllItems.show()
    setTimeout(() => {
    this.modalDeleteAllItemsFocusInput.nativeElement.focus();
    }, 500);
  }

  public pageChanged(e) {
    this.queryResponseArrived = false;
    this.page = e.page;
    this.getItems(this.SelectedItemList);
  }
  public onTableViewClick(){
    if(this.isTable){
      this.isTable = false
    }
    else{
      this.isTable = true
    }
  }
  public onBackClick(){
    this.showItemList = false
  }
  public onItemListEditClick(itemListName) {
    this.isItemListEdit = true;
    this.itemListName = "";
    this.isItemListEdit1 = false
    this.deletItemListEdit = false
    this.modalCreateItemList.show();
    setTimeout(() => {
      this.modalItemListFocusInput.nativeElement.focus();
    }, 500);
  }

  public onItemListDeleteClick(itemListName) {
    this.modalDeleteItemList.show();
  }

  public click ()
  {
    debugger;
    console.log(this.importedList)
  }

  // public onFileLoad(fileLoadedEvent) {
  //   const textFromFileLoaded = fileLoadedEvent.target.result;
  //   this.csvContent = textFromFileLoaded;
  //   var array = this.csvContent.split(/\r?\n/);
  //   var that = this;
  //   this.importedList = [];
  //   const arraySource = from(array);
  //   arraySource.subscribe(val =>{
  //     var array =  val.split(/,/);
  //     var importeditem = {} as IItem;
      
  //     importeditem.ICode = Number(array[1]);
  //     importeditem.IName = array[2];  
  //     if (array[0].length >= 2) {
  //       importeditem.HSN = array[0]
  //     } else {
  //       delete importeditem.HSN;
  //     }
  //     if ((array[3] !== undefined) && (array[3] !== null) && (array[3].length >= 1)) {
  //       importeditem.Unit = array[3]
  //     } else {
  //       delete importeditem.Unit;
  //     }
  //     importeditem.Price = [];
  //     importeditem.Price[0] = Object.create({});
  //     console.log(Number(array[4]));
  //     if (isNaN(Number(array[4])) !== true) {
  //       importeditem.Price[0].Price = Number(array[4])
  //     }
  //     if (isNaN(Number(array[5])) !== true) {
  //       importeditem.Price[0].CGST = Number(array[5])
  //     }
  //     if (isNaN(Number(array[6])) !== true) {
  //       importeditem.Price[0].SGST = Number(array[6])
  //     }
  //     if (isNaN(Number(array[7])) !== true) {
  //       importeditem.Price[0].IGST = Number(array[7])
  //     }
  //     importeditem.Price[1] = Object.create({});
  //     if (isNaN(Number(array[8])) !== true) {
  //       importeditem.Price[1].Price = Number(array[8])
  //     }
  //     if (isNaN(Number(array[9])) !== true) {
  //       importeditem.Price[1].CGST = Number(array[9])
  //     }
  //     if (isNaN(Number(array[10])) !== true) {
  //       importeditem.Price[1].SGST = Number(array[10])
  //     }
  //     if (isNaN(Number(array[11])) !== true) {
  //       importeditem.Price[1].IGST = Number(array[11])
  //     }

  //     importeditem.Price[2] = Object.create({});
  //     if (isNaN(Number(array[12])) !== true) {
  //       importeditem.Price[2].Price = Number(array[12])
  //     }
  //     if (isNaN(Number(array[13])) !== true) {
  //       importeditem.Price[2].CGST = Number(array[13])
  //     }
  //     if (isNaN(Number(array[14])) !== true) {
  //       importeditem.Price[2].SGST = Number(array[14])
  //     }
  //     if (isNaN(Number(array[15])) !== true) {
  //       importeditem.Price[2].IGST = Number(array[15])
  //     }
      
  //     if (isNaN(Number(array[16])) !== true) {
  //       importeditem.CP = Number(array[16])
  //     }
  //     if (isNaN(Number(array[17])) !== true) {
  //       importeditem.GCode = Number(array[17])
  //     }
  //     importeditem.GName = array[18]
  //     if (isNaN(Number(array[19])) !== true) {
  //       importeditem.DCode = Number(array[19])
  //     }
  //     importeditem.DName = array[20]

  //     importeditem.OpenPriceFlag = Boolean(array[21])
  //     importeditem.DecimalDisableFlag = Boolean(array[22])
  //     if (isNaN(Number(array[23])) !== true) {
  //       importeditem.DiscPercent = Number(array[23])
  //     }
  //     if (isNaN(Number(array[24])) !== true) {
  //       importeditem.CESS1 = Number(array[24])
  //     }
  //     if (isNaN(Number(array[25])) !== true) {
  //       importeditem.CESS2 = Number(array[25])
  //     }
  //     that.importedList.push(importeditem)
  //   }, null, () => {
  //     this.fileInputVariable.nativeElement.value = "";//clear Item file selected
  //     that.Items = that.importedList;
  //     that.Items.splice(-1, 1);
  //     if (Number.isNaN(that.Items[0].ICode) === true) {
  //       that.Items.splice(0, 1);
  //     }
  //     let putCalls = [];
  //     let isEnforce = true;
  //     for (var i = 0; i < ((that.Items.length / 100) | 0x00); i++) {
  //       let itemsSend = [];
  //       itemsSend = that.Items.slice((i * 100), ((i + 1) * 100));
  //       putCalls.push(this.itemsService.put(itemsSend, this.SelectedItemList, this.terminalToken, isEnforce));
  //     }
  //     if(that.Items.length % 100 != 0) {
  //       let itemsSend = [];
  //       itemsSend = that.Items.slice((that.Items.length - (that.Items.length % 100)), that.Items.length);
  //       putCalls.push(this.itemsService.put(itemsSend, this.SelectedItemList, this.terminalToken, isEnforce));
  //     }
  //     forkJoin(...putCalls).subscribe(result => {
  //       console.log('result: ', result);
  //       this.getItems(this.SelectedItemList);
  //       this.toasterService.success('Items successfully uploaded');
  //     }, error => {
  //       console.log('error: ', error);
  //       // this.toasterService.error('Error in sending file data to server');
  //       this.toasterService.error('Item file limit is 2000');

  //       this.getItems(this.SelectedItemList);
  //     })
  //   });
  // }

  public onFileLoad1(data){
    let putCalls = [];
    let isEnforce = true;
    // if(data.length > 2000){
    //   this.toasterService.error('Item file limit is 2000');
    // }
    this.modalWaitForUpload.show();
      for (var i = 0; i < ((data.length / 100) | 0x00); i++) {
        let itemsSend = [];
        itemsSend = data.slice((i * 100), ((i + 1) * 100));
        putCalls.push(this.itemsService.put(itemsSend, this.SelectedItemList, this.terminalToken, isEnforce));
      }
      if(data.length % 100 != 0) {
        let itemsSend = [];
        itemsSend = data.slice((data.length - (data.length % 100)), data.length);
        putCalls.push(this.itemsService.put(itemsSend, this.SelectedItemList, this.terminalToken, isEnforce));
      }
      forkJoin(...putCalls).subscribe(result => {
        console.log('result: ', result);
        this.getItems(this.SelectedItemList);
        this.toasterService.success('Items successfully uploaded');
        this.modalWaitForUpload.hide();
      }, error => {
        console.log(error,"FILE SENDING ERROR")
        this.toasterService.error('Error in sending file data to server');
        this.getItems(this.SelectedItemList);
        this.modalWaitForUpload.hide();
      })
  }

  arrayBuffer:any;
  file:File;
  onFileSelect(event,fileInput) 
  {
  this.file= event.target.files[0];   
    {
      let fileReader = new FileReader();
        fileReader.onload = (e) => {
            this.arrayBuffer = fileReader.result;
            var data = new Uint8Array(this.arrayBuffer);
            var arr = new Array();
            for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var bstr = arr.join("");
            var workbook = XLSX.read(bstr, {type:"binary"});
            var first_sheet_name = workbook.SheetNames[0];
            var worksheet = workbook.Sheets[first_sheet_name];
            let data1 = (XLSX.utils.sheet_to_json(worksheet,{raw:true}));
            this.prepareImportedList(data1);
            if(this.importedList === undefined){
              return 0;
            }
            this.onFileLoad1(this.importedList);
            this.importedList =[];
        }
        fileReader.readAsArrayBuffer(this.file);
    }
    fileInput .value = null;
  }
  public prepareImportedList(data){
    console.log(data);
    var flag = 0;
    let Error_msg;
    var importeditem = {} as IItem;
    for(let i= 0; i < data.length;i++){
      // importeditem = {} as IItem;
      importeditem.Price = [];
      importeditem.Price[0] = Object.create({});
      if(((data[i].IPRICE1) == null) || (data[i].IPRICE1<=9999999)){ 
        if (isNaN(Number(data[i].IPRICE1)) !== true ){
          importeditem.Price[0].Price = data[i].IPRICE1;
        } 
      } else {
          flag = 1;
          Error_msg = "Invalid Item Price1 for Item Code = "+ data[i].ICODE;
          break;
      }
      if(((data[i].IGST) == null) || (data[i].IGST<=100)){ 
        if (isNaN(Number(data[i].IGST)) !== true ) {
          importeditem.Price[0].IGST  = data[i].IGST;
        }
      }  else {
          flag = 1;
          Error_msg = "Invalid Item IGST1 for Item Code = "+ data[i].ICODE;
          break;
      } 
      if(((data[i].CGST) == null) || (data[i].CGST<=100)){
        if (isNaN(Number(data[i].CGST)) !== true){
          importeditem.Price[0].CGST  = data[i].CGST;
        }
      }else { 
        flag = 1;
        Error_msg ="Invalid Item CGST1 for Item Code = "+ data[i].ICODE;
        break;
      }
      if(((data[i].SGST) == null) || (data[i].SGST<=100)){
        if (isNaN(Number(data[i].SGST)) !== true) {
          importeditem.Price[0].SGST  = data[i].SGST;
        }
      } else { 
        flag = 1;
        Error_msg = "Invalid Item SGST1 for Item Code = "+ data[i].ICODE;
        break;
      }
      importeditem.Price[1] = Object.create({});
      if(((data[i].IPRICE2) == null) || (data[i].IPRICE2<=9999999)){ 
        if (isNaN(Number(data[i].IPRICE2)) !== true ){
          importeditem.Price[1].Price = data[i].IPRICE1;
        } 
      } else { 
        flag =1;
        Error_msg = "Invalid Item Price2 for Item Code = "+ data[i].ICODE;
        break;
      }
      if(((data[i].IGST_1) == null) || (data[i].IGST_1<=100)){
        if (isNaN(Number(data[i].IGST_1)) !== true) {
          importeditem.Price[1].IGST  = data[i].IGST_1;
        }
      } else {
        flag =1;
        Error_msg ="Invalid Item IGST2 for Item Code = "+ data[i].ICODE;;
        break;
      }
      if(((data[i].CGST_1) == null) || (data[i].CGST_1<=100)){
        if (isNaN(Number(data[i].CGST_1)) !== true) {
          importeditem.Price[1].CGST  = data[i].CGST_1;
        }
      } else {
        flag = 1;
        Error_msg = "Invalid Item CGST2 for Item Code = "+ data[i].ICODE;
        break;
      }
      if(((data[i].SGST_1) == null) || (data[i].SGST_1<=100)){
        if (isNaN(Number(data[i].SGST_1)) !== true) {
          importeditem.Price[1].SGST  = data[i].SGST_1;
        }
      } else { 
        flag = 1;
        Error_msg = "Invalid Item SGST2 for Item Code = "+ data[i].ICODE;
        break;
      }
      importeditem.Price[2] = Object.create({});
      if(((data[i].IPRICE3) == null) || (data[i].IPRICE3<=9999999)){ 
        if (isNaN(Number(data[i].IPRICE3)) !== true ){
          importeditem.Price[2].Price = data[i].IPRICE3;
        } 
      } else { 
        flag =1 ;
        Error_msg = "Invalid Item Price3 for Item Code = "+ data[i].ICODE;
        break;
      }
      if(((data[i].IGST_2) == null) || (data[i].IGST_2<=100)){
        if (isNaN(Number(data[i].IGST_2)) !== true) {
          importeditem.Price[2].IGST  = data[i].IGST_2;
        }
      }else { 
        flag = 1;
        Error_msg = "Invalid Item IGST3 for Item Code = "+ data[i].ICODE;
        break;
      }
      if(((data[i].CGST_2) == null) || (data[i].CGST_2<=100)){
        if (isNaN(Number(data[i].CGST_2)) !== true) {
          importeditem.Price[2].CGST  = data[i].CGST_2;
        } 
      } else { 
        flag =1 ;
        Error_msg = "Invalid Item CGST3 for Item Code = "+ data[i].ICODE;;
        break;
      }
      if(((data[i].SGST_2) == null) || (data[i].SGST_2<=100)){
        if (isNaN(Number(data[i].SGST_2)) !== true) {
          importeditem.Price[2].SGST  = data[i].SGST_2;
        }
      } else { 
        flag = 1;
        Error_msg = "Invalid Item SGST3 for Item Code = "+ data[i].ICODE ;
        break;
      }
      if ((data[i].INAME !== undefined) && (data[i].INAME !== null) && (data[i].INAME.length)<=50 ) {
        importeditem.IName = data[i].INAME;
      } else { 
        flag = 1;
        Error_msg  = "Invalid Item Name for Item code = "+ data[i].ICODE;
        break;
      }
      if ((data[i].HSN == undefined) || (data[i].HSN == null) || (data[i].HSN)) {
        importeditem.HSN = data[i].HSN;
      } else { 
        flag = 1;
        Error_msg = "Invalid Item HSN for Item code = " + data[i].ICODE;
        delete importeditem.HSN;
        break;
      }
      if ((data[i].UNIT !== undefined) && (data[i].UNIT !== null) && (data[i].UNIT.length)<4){
        importeditem.Unit = data[i].UNIT
      } else { 
        flag = 1;
        Error_msg = "Invalid Item UNIT for Item code = "+ data[i].ICODE;
        delete importeditem.Unit;
        break;
      }
      if((data[i].ICODE !== null) && (data[i].ICODE !== undefined) && (data[i].ICODE <= 2000)){
        importeditem.ICode = data[i].ICODE;
      } else {
        flag = 2;
        console.log(data[i].ICODE)
        // Error_msg = "Invalid Item CODE for Item = "+ data[i].INAME;
        this.toasterService.error("Invalid Item CODE for Item = "+ data[i].INAME);  
        // return 0;
        break;
      }
      if(((data[i].DCODE) == null) || (data[i].DCODE<=999)){
        if (isNaN(Number(data[i].DCODE)) !== true ){
          importeditem.DCode = data[i].DCODE;
        }
      } else {
      flag = 1;
      Error_msg = "Invalid Item DCODE for Item code = "+ data[i].ICODE;
      break;
      }
      if(( data[i].DNAME == null) || ( data[i].DNAME.length < 50)){
        importeditem.DName = data[i].DNAME;
      } else { 
        flag = 1;
        Error_msg  = "Invalid Item DNAME for Item code = "+ data[i].ICODE;
        break;
      }
      if(( data[i].GNAME == null) || ( data[i].GNAME.length < 50)){
        importeditem.GName = data[i].GNAME;
      } else { 
        flag = 1;
        Error_msg  = "Invalid Item GNAME for Item code = "+ data[i].ICODE;
        break;
      }
      if(((data[i].GCODE) == null) || (data[i].GCODE<=999)){
        if (isNaN(Number(data[i].GCODE)) !== true){
          importeditem.GCode = data[i].GCODE;
        }
      } else {
        flag = 1;
        Error_msg = " Invalid Item GCODE for Item code ="+ data[i].ICODE;
        break;
      }
      if(((data[i].CP) == null) || (data[i].CP<=9999999)){ 
        if (isNaN(Number(data[i].CP)) !== true) {
          importeditem.CP = data[i].CP;
        }
      } else {
        flag = 1;
        Error_msg = "Invalid Item Cost Price for Item code ="+ data[i].ICODE;
        break;
      }
      if(((data[i].CESS1) == null) || (data[i].CESS1<=100)){
        if (isNaN(Number(data[i].CESS1)) !== true) {
          importeditem.CESS1 = data[i].CESS1;
        }
      } else {
        flag = 1;
        Error_msg = "Invalid Item CESS1 for Item code = " + data[i].ICODE;
        break;
      }
      if(((data[i].CESS2) == null) || (data[i].CESS2<=100)){
        if (isNaN(Number(data[i].CESS2)) !== true) {
          importeditem.CESS2 = data[i].CESS2;
        }
      } else {
        flag = 1;
        Error_msg = "Invalid Item CESS2 for Item code = " + data[i].ICODE;
        break;
      }
      if(((data[i].DIS) == null) || (data[i].DIS<=100)){
        if (isNaN(Number(data[i].DIS)) !== true){
          importeditem.DiscPercent = data[i].DIS;
        }
      } else {
        flag = 1;
        Error_msg = " Invalid Item Discount Percent for Item code = "+ data[i].ICODE;
        break;
      }
      if(((data[i].DEC_NO) == null) || (data[i].DEC_NO<2)){
        importeditem.DecimalDisableFlag = Boolean(data[i].DEC_NO);
      } else {
        flag = 1;
        Error_msg = "Invalid Item Decimal Disable Flag no for Item code ="+ data[i].ICODE;
        break;
      }
      if(((data[i].OPF) == null) || (data[i].OPF<2)){
        importeditem.OpenPriceFlag = Boolean(data[i].OPF);
      } else {
        flag = 1;
        Error_msg = "Invalid Item OpenPrice Flag no for Item code ="+ data[i].ICODE;
        break;
      }
      this.importedList[i] = Object.assign({}, importeditem);
    }
    if(flag == 1)
      {
        delete this.importedList;
        this.toasterService.error(Error_msg);
      } else if(flag == 2){
        delete this.importedList;
      }
  }

  
  
  // onFileSelect(input: HTMLInputElement) {
  //   const files = input.files;
  //   if (files && files.length) {
  //     const fileToRead = files[0];
  //     const fileReader = new FileReader();
  //     fileReader.onload = this.onFileLoad.bind(this);
  //     fileReader.readAsText(fileToRead, "UTF-8");
  //   }
  // }

  public errorHandling(error) {
      if ((error === undefined) || (error === null)) {

      } else if ((error.error === undefined) || (error.error === null)) {

      }
    }

  public downloadReportFile(data: any, fileName) {
      const replacer = (key, value) => value === null ? '' : value; 
      const header = Object.keys(data[0]);
      let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
      csv.unshift(header.join(','));
      let csvArray = csv.join('\r\n');

      var blob = new Blob([csvArray], {type: 'text/csv' })
      saveAs(blob, fileName);
  }

  // public onExportReportClick(){
          
  //        this.SelectedItemList;
  //        let Itemsexport=[];
  //         this.itemsService.getItems(  this.SelectedItemList, this.IName, this.terminalToken, 1, 2000)
  //         .subscribe(resp => {  
  //         Itemsexport= resp.docs;
  //         // this.itemsList = resp
  //         if (Itemsexport !== undefined) {
  //           for (let j = 0; j < Itemsexport.length; j++) {
  //             for (let i = 0; i < 10; i++) {
  //               if (this.Items[j].Price[i] === undefined) {
  //                 this.Items[j].Price[i] = {};
  //             }
  //           }
  //           this.queryResponseArrived = false;
  //         }}
  //         if(Itemsexport === undefined){
  //             this.tempObject = {};
  //             this.samplefileformat;
  //               this.downloadReportFile(  this.samplefileformat, "Item.csv"); 
  //           } else {
  //         for (let i = 0; i <Itemsexport.length; i++) {   
  //           this.tempObject = {};
  //           this.tempObject.HSN = Itemsexport[i].HSN;
  //           this.tempObject.ICode= Itemsexport[i].ICode;
  //           this.tempObject.IName  = Itemsexport[i].IName;      
  //           this.tempObject.Unit =Itemsexport[i].Unit;
  //           this.tempObject.IPrice1= Itemsexport[i].Price[0].Price
  //           this.tempObject.CGST1= Itemsexport[i].Price[0].CGST
  //           this.tempObject.SGST1 = Itemsexport[i].Price[0].SGSTres         
  //           this.tempObject.IGST1 = Itemsexport[i].Price[0].IGST
  //           this.tempObject.IPrice2=Itemsexport[i].Price[1].Price
  //           this.tempObject.CGST2 =Itemsexport[i].Price[1].CGST
  //           this.tempObject.SGST2=Itemsexport[i].Price[1].SGST
  //           this.tempObject.IGST2=Itemsexport[i].Price[1].IGST
  //           this.tempObject.IPrice3= Itemsexport[i].Price[2].Price
  //           this.tempObject.CGST3 =Itemsexport[i].Price[2].CGST
  //           this.tempObject.SGST3=Itemsexport[i].Price[2].SGST
  //           this.tempObject.IGST3=Itemsexport[i].Price[2].IGST
  //           this.tempObject.CP =Itemsexport[i].CP;
  //           this.tempObject.GCode =Itemsexport[i].GCode;
  //           this.tempObject.GName=Itemsexport[i].GName;
  //           this.tempObject.DCode =Itemsexport[i].DCode;
  //           this.tempObject.DName =Itemsexport[i].DName;
  //           this.tempObject.OPF=Itemsexport[i].OpenPriceFlag ? 1 : 0;
  //           this.tempObject.Decimalstatus = Itemsexport[i].DecimalDisableFlag ? 1 : 0;
  //           this.tempObject.Dis =Itemsexport[i].DiscPercent;
  //           this.tempObject.CESS1=Itemsexport[i].CESS1;
  //           this.tempObject.CESS2= Itemsexport[i].CESS2;
  //           Itemsexport[i]={};
  //           Itemsexport[i]= Object.assign(this.tempObject);
  //         }
  //       }
  //       this.downloadReportFile(Itemsexport, "Item.csv"); 
  //     })    
  // }
  pages(SelectedItemList1: any, IName: any, terminalToken: string, pages: any, limit: number) {
    throw new Error("Method not implemented.");
  }



  public onExportReportClick(){
          
    this.SelectedItemList;
    let Itemsexport=[];
     this.itemsService.getItems(  this.SelectedItemList, this.IName, this.terminalToken, 1, 2000)
     .subscribe(resp => {  
     Itemsexport= resp.docs;

     if (this.Items !== undefined) {
      for (let j = 0; j <  Itemsexport.length; j++) {
        for (let i = 0; i < 3; i++) {
          if ( Itemsexport[j].Price[i] === undefined) {
            Itemsexport[j].Price[i] = {};
          }
        }
      }
      this.queryResponseArrived = true;
    }
   
     if(Itemsexport === undefined){
         this.tempObject = {};
         this.samplefileformat;
           this.downloadReportFile(  this.samplefileformat, "Item.csv"); 
       } else {
     for (let i = 0; i <Itemsexport.length; i++) {   
       this.tempObject = {};
       this.tempObject.HSN = Itemsexport[i].HSN;
       this.tempObject.ICode= Itemsexport[i].ICode;
       this.tempObject.IName  = Itemsexport[i].IName;      
       this.tempObject.Unit =Itemsexport[i].Unit;
       this.tempObject.IPrice1= Itemsexport[i].Price[0].Price
       this.tempObject.CGST1= Itemsexport[i].Price[0].CGST
       this.tempObject.SGST1 = Itemsexport[i].Price[0].SGSTres         
       this.tempObject.IGST1 = Itemsexport[i].Price[0].IGST
       this.tempObject.IPrice2=Itemsexport[i].Price[1].Price
       this.tempObject.CGST2 =Itemsexport[i].Price[1].CGST
       this.tempObject.SGST2=Itemsexport[i].Price[1].SGST
       this.tempObject.IGST2=Itemsexport[i].Price[1].IGST
       this.tempObject.IPrice3= Itemsexport[i].Price[2].Price
       this.tempObject.CGST3 =Itemsexport[i].Price[2].CGST
       this.tempObject.SGST3=Itemsexport[i].Price[2].SGST
       this.tempObject.IGST3=Itemsexport[i].Price[2].IGST
       this.tempObject.CP =Itemsexport[i].CP;
       this.tempObject.GCode =Itemsexport[i].GCode;
       this.tempObject.GName=Itemsexport[i].GName;
       this.tempObject.DCode =Itemsexport[i].DCode;
       this.tempObject.DName =Itemsexport[i].DName;
       this.tempObject.OPF=Itemsexport[i].OpenPriceFlag ? 1 : 0;
       this.tempObject.Decimalstatus = Itemsexport[i].DecimalDisableFlag ? 1 : 0;
       this.tempObject.Dis =Itemsexport[i].DiscPercent;
       this.tempObject.CESS1=Itemsexport[i].CESS1;
       this.tempObject.CESS2= Itemsexport[i].CESS2;
       Itemsexport[i]={};
       Itemsexport[i]= Object.assign(this.tempObject);
     }
   }
   this.downloadReportFile(Itemsexport, "Item.csv"); 
 })    
}

}