import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'
import { SubscriptionRoutingModule } from './subscription-routing.module';
import { SubscriptionComponent } from './subscription.component';
import { SubscriptionService } from 'app/services/subscription.service';
import { ModalModule } from 'ngx-bootstrap';
import { ToasterModule } from 'angular2-toaster';

@NgModule({
  imports: [
    CommonModule,
    SubscriptionRoutingModule,
    FormsModule,
    ToasterModule,
    ModalModule
  ],
  providers: [SubscriptionService],
  declarations: [SubscriptionComponent]
})
export class SubscriptionModule { }
