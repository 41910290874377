import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable,throwError,of} from 'rxjs';
import {SettingsService} from '../settings/settings.service';;
import {mergeMap,catchError} from 'rxjs/operators'

@Injectable()
export class SubscriptionService {

  constructor(private http:HttpClient,private settings:SettingsService) {
    console.log('subscription created')
   }

  public getList(): Observable < any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    const token = localStorage.getItem('token')
    if(token){
      headers = headers.append ('x-access-token', token)
    }
    var url = this.settings.getUrlsubscription();
    params = params.append('page', '1')
    params = params.append('limit', '50' )
    return this.http.get(url, { headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  // public getSubscriptionPlan(s_id): Observable < any > {
  //   let headers = new HttpHeaders();
  //   let params = new HttpParams()
  //   const token = localStorage.getItem('token')
  //   if(token){
  //     headers = headers.append ('x-access-token', token)
  //   }
  //   var url = this.settings.getUrlsubscription();
  //   params = params.append('subscriptionId', s_id )
  //   return this.http.get(url, { headers: headers, params: params}).pipe(mergeMap(val=>{
  //     return of(val)
  //   }), catchError((err:Response) => {
  //     return  throwError(err)
  //   }));
    
  // }

  


  public getSubscriptionPlan(body, token): Observable < any > {
    let headers = new HttpHeaders();
    if(token) {
      headers = headers.append('terminaltoken', token)
    }
    var url = this.settings.getUrlsubscriptionbytags();
    return this.http.post(url,body,{headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
    
  }


  
  public post(value): Observable <any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    const token = localStorage.getItem('token')
    console.log(token)
    if (token) {
      headers = headers.append('x-access-token',token)
    }
      var url = this.settings.getUrlsubscription();
      return this.http.post(url, value, {headers: headers} ).pipe(mergeMap(val=>{
        return of(val)
      }), catchError((err:Response) => {
        return  throwError(err)
      }));
  }


    
  public patch(value): Observable <any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    const token = localStorage.getItem('token')
    console.log(token)
    if (token) {
      headers = headers.append('x-access-token',token)
    }
      var url = this.settings.getUrlsubscription();
       return this.http.patch(url, value, {headers: headers} ).pipe(mergeMap(val=>{
        return of(val)
      }), catchError((err:Response) => {
        return  throwError(err)
      }));
  }

  public delete(value): Observable <any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    params = params.append('subscriptionId', value._id);
    console.log(params)
    const token = localStorage.getItem('token')
    console.log(token)
    if (token) {
      headers = headers.append('x-access-token',token)
    }
      var url = this.settings.getUrlsubscription();
       return this.http.delete(url, {headers: headers , params: params} ).pipe(mergeMap(val=>{
        return of(val)
      }), catchError((err:Response) => {
        return  throwError(err)
      }));
  }


}
