import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable,throwError,of} from 'rxjs';
import {SettingsService} from '../settings/settings.service';
import {mergeMap,catchError} from 'rxjs/operators';

@Injectable()
export class ItemsService {

  constructor(private http:HttpClient,private setting:SettingsService) {
    console.log('item service created')
   }

  //  terminalToken: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1Y2M5OTI2MzE3Y2JmOTY0YTM0MGFjMzUiLCJ1c2VydHlwZSI6ImRlZmF1bHQiLCJpYXQiOjE1NjA4NTc0MjA3NjUsImV4cCI6MTU2NjA0MTQyMDc2NSwiZGV2aWNlSWQiOiI1Y2RkMzQ1MjI0ODMyYmZmNmQzN2Q1NjQiLCJidXNpbmVzc0lkIjoiNWNmYTU2ZWQyZjI5ZjcwMjA4MzQwZDI4Iiwib3V0bGV0SWQiOiI1Y2NkOGI2NjkxYzM1MDY5N2I1OWQ3YzEifQ.hMkgg6f-88vpikKd_aaalWtO0D_nPVHDmTy0BOhVaXo';


   public getItems(id, IName, terminaltoken, page, limit): Observable < any > {
    let headers=new HttpHeaders();
    let params=new HttpParams()
   // const token=localStorage.getItem('token')
    const url = this.setting.getItemUrl()
    const token=localStorage.getItem('token')
    if(terminaltoken){
      headers = headers.append('terminaltoken', terminaltoken);
    }

    console.log('getItems ', id)
    // console.log('getItems ', terminaltoken)
    params=params.append('ItemListId',id)
    params=params.append('Limit', limit)
    // params=params.append('deleteStatus','false')
    params=params.append('Page', page)
    params=params.append('SortBy', 'Timestamp')
    if(IName != '') {
    params=params.append('IName',IName)
    }
    
    return this.http.get(url, { headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  public getItemCodeForList(id, terminaltoken): Observable < any > {
    let headers=new HttpHeaders();
    let params=new HttpParams()
    const url = this.setting.getItemCodeUrl();

    if(terminaltoken){
      headers = headers.append('terminaltoken', terminaltoken);
    }

    console.log('getItems ', id)
    params=params.append('ItemListId', id)
    return this.http.get(url, { headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }


  public post(item,ItemListId,terminaltoken): Observable < any > {
    let headers=new HttpHeaders();
    let body = {
      ItemListId: ItemListId,
      item:[item]
    }
    console.log('body  ',body)
    const url = this.setting.getItemUrl()
    const token=localStorage.getItem('token')
    if(token){
    headers=headers.append('terminaltoken',terminaltoken);
    }
    return this.http.post(url, body, {headers: headers}).pipe(mergeMap(val=>{
      if(val['error'] !== undefined)
        return this.http.put(url, body, {headers: headers}).pipe(catchError((err:Response) => {
          return  throwError(err)
        }))
      else
        return of(val)
    }), catchError((err:Response) => {
      return  throwError(err) 
    }));
  }
  
  public put(item,ItemListId,terminaltoken,isEnforce): Observable < any > {
    let headers=new HttpHeaders();
    let body = {
      ItemListId: ItemListId,
      isEnforce: isEnforce,
      item: item
    }
    //console.log('body ', body.isEnforce)
    const url = this.setting.getItemUrl()
    const token = localStorage.getItem('token')
    if (token) {
    headers = headers.append('terminaltoken', terminaltoken);
    } 
    return this.http.put(url, body, {headers: headers}).pipe(mergeMap(val=>{
      if(val['error'] !== undefined)
        return this.http.post(url, body, {headers: headers}).pipe(catchError((err:Response) => { 
          return  throwError(err)
        }))
      else
        return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }


  public delete(Item,terminaltoken): Observable < any > {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    // const item = {
    //     ICode : Item.ICode,
    // }
    params = params.append('itemId', Item._id);
    params = params.append('ItemListId', Item.ItemListId);

    const url = this.setting.getItemUrl()
    const token = localStorage.getItem('token')
    if (token) {
      headers = headers.append('terminaltoken', terminaltoken);
    }
    return this.http.delete(url, {headers : headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  public deleteAllItems(itemListId, terminaltoken): Observable < any > {
    let headers = new HttpHeaders();
    let params = new HttpParams();
    params = params.append('ItemListId', itemListId);

    const url = this.setting.getDeleteAllItemUrl()
    const token = localStorage.getItem('token')
    if (token) {
      headers = headers.append('terminaltoken', terminaltoken);
    }
    return this.http.delete(url, {headers : headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

}
