const Ajv = require('ajv');
const cv = require('./commonValidation')

const ajv = Ajv({
    allErrors: true
});

const ICode = cv.ICode
const DCode = cv.DCode
const GCode = cv.GCode
const SKU = cv.SKU
const HSN = cv.HSN
const DName =cv.DName
const GName =cv.GName
const IName =cv.IName
const Unit = cv.Unit
const CP = cv.CP
const Price =cv.Price
const IGST = cv.IGST
const SGST = cv.SGST
const CGST = cv.CGST
const CESS1 =cv.CESS1
const CESS2 = cv.CESS2
const DiscPercent =cv.DiscPercent
const OpenPriceFlag =cv.OpenPriceFlag
const DecimalDisableFlag =cv.DecimalDisableFlag
const ItemListId = cv.Id
const Timestamp = cv.Timestamp
const SortBy = cv.SortBy
const Limit = cv.limit
const Page = cv.page
const GetDeletedItem = cv.GetDeletedItem
const itemId = cv.Id
const businessId = cv.Id
const outletId = cv.Id
const deviceId = cv.Id

const ItemDSchema = {
    type: 'object',
    properties: {
        Timestamp,
        ItemListId,
        item: {
            type: "array",
            minItems: 1,
            maxItems: 50,
            items: {
                type: 'object',
                properties: {
                    ICode,
                    DCode,
                    GCode,
                    SKU,
                    HSN,
                    DName,
                    GName,
                    IName,
                    Unit,
                    CP,
                    Price: {
                        type: "array",
                        minItems: 1,
                        maxItems: 3,
                        items: {
                            type: "object",
                            properties: {
                                Price,
                                IGST,
                                SGST,
                                CGST
                            },
                            additionalProperties: false,
                            required: ['Price']
                        }
                    },
                    DiscPercent,
                    CESS1,
                    CESS2,
                    OpenPriceFlag,
                    DecimalDisableFlag
                },
                additionalProperties: false,
                required: ['IName']
            }
        }
    },
    additionalProperties: false,
    required: ['ItemListId', 'item']
}

const tokenValidationSchema = {
    type: 'object',
    properties: {
        businessId,
        outletId,
        deviceId
    },
    additionalProperties: true,
    required: ['businessId']
}

const GetDSchema = {
    type: 'object',
    properties: {
        ItemListId,
        Timestamp,
        GetDeletedItem,
        Limit,
        Page,
        SortBy
    },
    additionalProperties: false,
    required: ['ItemListId', 'Limit', 'Page']
}

const GetByIdDSchema = {
    type: 'object',
    properties: {
        ItemListId,
        ICode,
        SKU
    },
    additionalProperties: false,
    required: ['ItemListId'],
    anyOf: [
        {required: ['ICode']},
        {required: ['SKU']},
    ]
}

const DeleteDSchema = {
    type: 'object',
    properties: {
        Timestamp,
        ItemListId,
        itemId,
        item: {
            type: "array",
            minItems: 1,
            maxItems: 50,
            items: {
                type: 'object',
                properties: {
                    ICode,
                    SKU
                },
                additionalProperties: false,
                required: ['ICode']
            }
        }
    },
    additionalProperties: false,
    required: ['ItemListId'],
    oneOf : [
        {required: ['item']},
        {required: ['itemId']}
    ]
}

const UniqueICodeSchema = {
    type: 'array',
    "uniqueItems": true,
    items: {
        type: "number"
    }
}

const validate = (schema) => {
  debugger;
    return ajv.compile(schema);
}
function VBItem(obj) {
  let x= validate(ItemDSchema);
  return x(obj)
}
// VBItem= validate(ItemDSchema);
// VBGet= validate(GetDSchema);
// VBGetByCode= validate(GetByIdDSchema);
// VBDelete= validate(DeleteDSchema);
// VBIsUniqueICode= validate(UniqueICodeSchema);
// okenItemValidation = validate(tokenValidationSchema);
export {VBItem,ItemDSchema};
