import { Component, OnInit,TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import{ ToastrService} from 'ngx-toastr'
import { SettingsService } from 'app/settings/settings.service';
import {SubscriptionService} from '../../services/subscription.service'

interface Details {
  Description: String
  Price: Number
  Discount: Number
  Currency: String
  Duration: Number
  GSTPercent: Number
  CESSPercent: Number
  OtherPercent: Number
}

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {
  @ViewChild('templateCreateSubscription', {static: false})
  private TemplateCreateSubscription : TemplateRef<any>

  @ViewChild('templateMapSubscription', {static: false})
  private TemplateMapSubscription : TemplateRef<any>

  modalRefCreateSubscription: BsModalRef;
  // sub: any = { OfferDetails: []};
  public sub : any = {}
  public offerDetailsArray = [{}];
  public TagsArray = [{
    Description : "All"
  }];
  offerObject;

  // OffersList: any[];
  selectedRow: Number;
  Offer: any={OfferDetails:[]};
  templateMap;
  public OffersList;
  medium;
  TagName;
  public editOfferDetails;

  constructor(private sservice:SubscriptionService,
    private toasterService: ToastrService,
    private modalService: BsModalService

    ) { }


  ngOnInit() {
   this.getSubscriptionList();
  }

  public getSubscriptionList(){
    this.sservice.getList()
    .subscribe(resp => {
      console.log("resp ",resp);
      this.OffersList = resp;
    });
  }

  public createSubscription(){
    this.sub.OfferDetails = this.offerDetailsArray;
    this.sub.Tag = this.TagsArray;
    // this.sub.fromDate = new Date(this.sub.fromDate)
    // this.sub.toDate = new Date(this.sub.toDate)
    let obj = this.sub;
    console.log(obj)
    this.sservice.post(obj)
    .subscribe(resp =>{
      this.toasterService.success('Subscription Created Successfully');
      this.modalRefCreateSubscription.hide();
      this.offerDetailsArray = [{}];
      this.TagsArray = [{
        Description : "All"
      }];
      this.sub = {}
      this.getSubscriptionList();
    }, err => {
      console.log("ERROR in the Subscription adding",err);
      this.toasterService.error("Error Occured while Creating");
    })
  }

  public openModal(template: any) {
    this.modalRefCreateSubscription = this.modalService.show(template,Object.assign({}, { class: "modal-lg" }));
  }
  public offerClick(data){
    this.Offer = data;
  }

  public addOfferDetails(){
    this.offerObject = {} as Details;
    this.offerDetailsArray.push(this.offerObject)
  }

  public editModelCloseEvent(){
    this.modalRefCreateSubscription.hide();
      this.offerDetailsArray = [{}];
      this.TagsArray = [{
        Description : "All"
      }];
      this.sub = {}
  }


  public removeOfferDetails(index){
    console.log(index);
    this.offerDetailsArray.splice(index, 1);
  }

  public addingTag(tag){
    let obj = {
      Description : tag
    }
    this.TagsArray.push(obj);
    this.TagName = null;
  }

  public removeTag(index){
    this.TagsArray.splice(index, 1);
  }

  public editOfferClick(offer,templateEditSubscription){
    console.log("double click" , offer);
    this.sub.subscriptionId = offer._id;
    this.sub.OfferName = offer.OfferName;
    this.sub.fromDate = offer.fromDate;
    this.sub.toDate = offer.toDate;
    this.offerDetailsArray = offer.OfferDetails;
    this.TagsArray = offer.Tag;
    this.openModal(templateEditSubscription);
  }

  public updateSubscription(){
    this.sub.OfferDetails = this.offerDetailsArray;
    this.sub.Tag = this.TagsArray;
    // this.sub.fromDate = new Date(this.sub.fromDate)
    // this.sub.toDate = new Date(this.sub.toDate)
    let obj = this.sub;
    delete obj._id;
    console.log(obj)
    this.sservice.patch(obj)
    .subscribe(resp =>{
      this.toasterService.success('Subscription Updated Successfully');
      this.modalRefCreateSubscription.hide();
      this.offerDetailsArray = [{}];
      this.TagsArray = [{
        Description : "All"
      }];
      this.sub = {}
      this.getSubscriptionList();
    }, err => {
      console.log("ERROR in the Subscription Updating",err);
      this.toasterService.error("Error Occured while updating");
    })
  }

  public deleteSubscritpion(offer){
    let obj = offer;
    this.sservice.delete(obj)
    .subscribe(resp =>{
      this.toasterService.success('Subscription Deleted Successfully');
      this.getSubscriptionList();
    }, err => {
      console.log("ERROR in the Subscription Deleting",err);
      this.toasterService.error("Error Occured while Deleting");
    })
  }

}
