import {
  Injectable
} from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable,throwError,of} from 'rxjs';
import {SettingsService} from '../settings/settings.service';
import {mergeMap,catchError} from 'rxjs/operators'

interface User {
  email: String,
    username: String,
    usertype: String,
    firstname: String,
    lastname: String,
    facebookID: String,
    imageUrl: String,
    mobile: String,
    telephone: String,
    alternatemobile: String,
    businessname: String,
    address: String,
    city: String,
    pin: String,
    state: String,
    country: String,
    profilepic: String,
    gps: String,
    accountstatus: String,
}

@Injectable()
export class UserService {

  constructor(private http: HttpClient, private settings: SettingsService) {
    console.log('here in ProductService init')
  }

  getUserDetails(id: any): Observable < any > {
    let headers = new HttpHeaders();
    console.log('getUserDetails');
    const token = localStorage.getItem('token')
    if (token) {
      headers = headers.append('x-access-token', token)
    }
    var url = this.settings.getBackEndUrlUser() + '/admin/get/' + id;
    console.log(url)
    return this.http.get(url, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }



  getUserDetailsAll(itemsPerpage: any, page: any, searchstr: string): Observable < any > {
    let headers = new HttpHeaders();
    let params=new HttpParams()
    var token = localStorage.getItem('token')
    var orgntoken = localStorage.getItem('orgntoken')
    params = params.append('limit', itemsPerpage.toString());
    params = params.append('page', page.toString());
    if (orgntoken)
      headers = headers.append('x-access-orgntoken', orgntoken);
    if (token)
      headers = headers.append('x-access-token', token);

    var url = this.settings.getBackEndUrlUser();
    return this.http.get(url, {headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  getUserDetail(body): Observable < any > {
    let headers = new HttpHeaders();
    let params=new HttpParams()
    let bodyKeys = [];
    var token = localStorage.getItem('token')
    var orgntoken = localStorage.getItem('orgntoken');
    
    bodyKeys = Object.keys(body);
    for (var i = 0; i < bodyKeys.length; i++) {
      params = params.append(bodyKeys[i], body[bodyKeys[i]]);
    }
    if (orgntoken)
      headers = headers.append('x-access-orgntoken', orgntoken);
    if (token)
      headers = headers.append('x-access-token', token);

    var url = this.settings.getBackEndUrlUser();
    return this.http.get(url, {headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }


  usertype: string;

  getUserDetailsFilter(itemsPerpage: any, page: any, searchstr: string): Observable < any > {
    // var currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let headers = new HttpHeaders();
    console.log("getUserDetails " + searchstr);
    var token = localStorage.getItem('token')
    var orgntoken = localStorage.getItem('orgntoken')
    headers = headers.append('limit', itemsPerpage.toString())
    headers = headers.append('page', page.toString())
    if (orgntoken)
      headers = headers.append('x-access-orgntoken', orgntoken)
    if (token)
      headers = headers.append('x-access-token', token)

    var url: any;
    console.log(searchstr)
    if (searchstr === 'All' || searchstr === undefined) {
      url = this.settings.getBackEndUrlUser() + '/getquery/query?';
    } else {
      url = this.settings.getBackEndUrlUser() + '/getquery/query?usertype=' + searchstr;
    }

    console.log("url $$$$ ",url)
    return this.http.get(url, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

    //.map((res: Response) => res.json())

  }
  registerUser(user): Observable < any > {
    const headers = new HttpHeaders();
    const url = this.settings.getBackEndUrlUser();
     console.log('url ', url);
    return this.http.post(url, user, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  getOTP(user): Observable < any > {
    const headers = new HttpHeaders();
    const url = this.settings.getBackEndUrlUser()+'/mobile/otp/'+user.mobile;
    console.log('url ', url);

    return this.http.post(url, user, {headers: headers}).pipe(mergeMap(val=>{
        return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  sendOTPLogin(user): Observable < any > {
    const headers = new HttpHeaders();
    const url = this.settings.getBackEndUrlUser()+'/login';
    console.log('url ', url);
    return this.http.post(url, user, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }

  forgetPassword(user): Observable < any > {
    const headers = new HttpHeaders();
    const url = this.settings.getBackEndUrlUser()+'/forgetpassword';
    console.log('url ', url);
    return this.http.patch(url, user, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }


  editpassword(user): Observable < any > {
    let headers = new HttpHeaders();
    const token = localStorage.getItem('token')
    if (token){
      headers = headers.append('x-access-token', token)
    }
    const url = this.settings.getBackEndUrlUser()+'/editpassword';
    console.log('url ', url);
    return this.http.patch(url, user, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));
  }


  deleteuser(id): Observable < any > {
    let headers = new HttpHeaders();
    console.log("getItemList");
    const token = localStorage.getItem('token')
    if (token){
      headers = headers.append('x-access-token', token)
    }
    const url = this.settings.getBackEndUrlUser();
    return this.http.delete(url + '/admin/' + id, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  postUser(user: User): Observable < any > {
    // var currentContact = JSON.parse(localStorage.getItem('currentContact'));
    let headers = new HttpHeaders();
    console.log("getItemList");

    // var token=localStorage.getItem('creator')
    var orgntoken = localStorage.getItem('orgntoken')
    if (orgntoken)
      headers = headers.append('x-access-orgntoken', orgntoken)
    // if(token)
    headers = headers.append('creator', 'admin')

    let url = this.settings.getBackEndUrlUser() + '/register';
    console.log(headers);
    console.log(JSON.stringify(user))
    return this.http.post(url, (user), {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  putUser(user): Observable < any > {
    // var currentContact = JSON.parse(localStorage.getItem('currentContact'));
    let headers = new HttpHeaders();
    console.log("user -- " + user);
    var token = localStorage.getItem('token')
    if (token)
      headers = headers.append('x-access-token', token)

    let url = this.settings.getBackEndUrlUser();
    console.log(headers);
    console.log(url);
    return this.http.put(url, (user), {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  edituserType(user): Observable < any > {
    const data = user;
    let headers = new HttpHeaders();
    var token = localStorage.getItem('token')
    if (token) {
      headers = headers.append('x-access-token', token)
    }
    let url = this.settings.getBackEndUrlUserEditUser();
    console.log(url);
    return this.http.patch(url, data, {headers: headers}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }


}

