import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogosettingRoutingModule } from './logosetting-routing.module';
import { FormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
import { ToasterModule } from 'angular2-toaster';
import { DevicesService } from 'app/services/devices.service';
import { LogosettingComponent } from './logosetting.component';

@NgModule({
  declarations: [ LogosettingComponent],
  imports: [
    CommonModule,
    LogosettingRoutingModule,
    FormsModule,
    ModalModule,
    ToasterModule
  ],
  providers: [DevicesService]

})
export class LogosettingModule { }
