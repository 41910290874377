import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable,throwError,of} from 'rxjs';
import {SettingsService} from '../settings/settings.service';
import {mergeMap,catchError} from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})

export class WarrantytcsService {

  constructor(private http:HttpClient, private settings:SettingsService) { 
    console.log('Warrantytcs created')
  }

  public getList(body): Observable < any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    const token = localStorage.getItem('token')
    if(token){
      headers = headers.append ('x-access-token', token)
    }
    params = params.append('warrantyTCStatus', body.warrantyTCStatus)
    var url = this.settings.getUrlWarrantytcs();
    return this.http.get(url, { headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }

  public getById(body): Observable < any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    const token = localStorage.getItem('token')
    if(token){
      headers = headers.append ('x-access-token', token)
    }
    params = params.append('warrantyTCId', body.warrantyTCId)
    var url = this.settings.getUrlWarrantytcs();
    return this.http.get(url + '/byId', { headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }


  public post(value): Observable <any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    const token = localStorage.getItem('token')
    if (token) {
      headers = headers.append('x-access-token',token)
    }
      var url = this.settings.getUrlWarrantytcs();
      return this.http.post(url, value, {headers: headers} ).pipe(mergeMap(val=>{
        return of(val)
      }), catchError((err:Response) => {
        return  throwError(err)
      }));
  }


    
  public patch(value): Observable <any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    const token = localStorage.getItem('token')
    console.log(token)
    if (token) {
      headers = headers.append('x-access-token',token)
    }
      var url = this.settings.getUrlWarrantytcs();
       return this.http.patch(url, value, {headers: headers} ).pipe(mergeMap(val=>{
        return of(val)
      }), catchError((err:Response) => {
        return  throwError(err)
      }));
  }

  public delete(value): Observable <any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    params = params.append('warrantyTCId', value._id);
    console.log(params)
    const token = localStorage.getItem('token')
    console.log(token)
    if (token) {
      headers = headers.append('x-access-token',token)
    }
      var url = this.settings.getUrlWarrantytcs();
       return this.http.delete(url, {headers: headers , params: params} ).pipe(mergeMap(val=>{
        return of(val)
      }), catchError((err:Response) => {
        return  throwError(err)
      }));
  }

}
