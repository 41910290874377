import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable,throwError,of} from 'rxjs';
import {SettingsService} from '../settings/settings.service';;
import {mergeMap,catchError} from 'rxjs/operators'


@Injectable()
export class ProductService {

  constructor(private http:HttpClient,private settings:SettingsService) {
    console.log('product created')
   }

  public getList(): Observable < any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    const token = localStorage.getItem('token')
    if(token){
      headers = headers.append ('x-access-token', token)
    }
    var url = this.settings.getBackEndUrlProduct();
    params = params.append('page', '1')
    params = params.append('limit', '50' )
    return this.http.get(url, { headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }


  public getSettings(): Observable < any > {
    let headers = new HttpHeaders();
    let params = new HttpParams()
    const token = localStorage.getItem('token')
    if(token){
      headers = headers.append ('x-access-token', token)
    }
    var url = this.settings.getBackEndUrlProduct();
    params = params.append('page', '1')
    params = params.append('limit', '50' )
    return this.http.get(url, { headers: headers, params: params}).pipe(mergeMap(val=>{
      return of(val)
    }), catchError((err:Response) => {
      return  throwError(err)
    }));

  }


}
